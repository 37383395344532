import { BrowserRouter, Router, Routes, Route, NavLink, useLoaderData } from 'react-router-dom'
import { useContext, useState } from 'react';
import { userdata } from '../App'
import { useEffect } from 'react';

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import user_logo from '../images/user_1.jpg'
import non_user_logo from '../images/non-user-icon.svg'
import ikco_logo from '../images/Ikco_Logo_1.png'

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import './navbar.css';
import Modal from './Modal';
import { useFetch } from '../hooks/useFetch';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

const Navbar =()=>{ 
    
    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear, resultInfo,setResultInfo
      } = useContext(userdata)

      const [showWait,setShowWait] = useState(false)
      const modalClosed=()=>{
        setPmShowWait(false)
        
      }

    const [isActive, setIsActive] = useState(true);

    const ToggleClass = () => {
        setIsActive(!isActive);
    };
    const menueClose =()=>{
        if(!isActive) setIsActive(!isActive);
    }

    const do_logoutt=()=>{
        setIsLoggedin(false)
        setUserNumber("")
        localStorage.clear() 
        setFullName('میهمان')
        setTaskHint(' ')
    }
    useEffect(()=>{
        const isLoggedin1 = localStorage.getItem('isLoggedin')

        if (isLoggedin1 === '1') {
            setFullName(localStorage.getItem('fullname'))
        } else {
            setFullName('میهمان')
        }
       },[])

       const [url1, setUrl1] = useState('https://rezasayyahi.ir/dbtest/pmlist.php');
       const {data : pmlist, isLoading:pmLoading, error:pmError} = useFetch(url1);

       const [url, setUrl] = useState('https://rezasayyahi.ir/dbtest/machinlist.php');
       const {data : db_machinlist, isLoading:mLoading, error:mError} = useFetch(url);

    //----------------------------------------------------------------------------------
  const fetchData = ()=>{
    const alluser = 'alluser'
    const pass = '0000'

    fetch('https://rezasayyahi.ir/dbtest/user_login.php',{

        method:'POST',
        headers:{
            'Accept' : 'applicaion/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            usernumber: alluser,
            password: pass
        })
    }).then((response)=>response.json())
        .then((responseJson)=>{
            setUserList(responseJson)
            setShowWait(false)
            responseJson.map((data , index)=> {
              if (data.User_Number == userNumber) {
                setWorkCenter(data.Work_Center);
                setUserShopLevel(data.Access_Shop);
                setUserWorkLevel(data.Access_Work_Center)
                setUserPosition(data.Position)
              }
            })
        }).catch((error)=>{ 
            alert(error)
        })
  }
//--------------------------------------------------------------------

    //-----------------------------------------------------------------------------
    const handler= (btnSent)=>{
      console.log('userPosition = ', userPosition)
      console.log('btnSent = ', btnSent)
        var setORtask;
        var task = false
        var user = false
        var setting = false
        var summery = false
        var history = false

        if (btnSent==='settings' & (userPosition === 'man' || userPosition === 'planner' || userPosition === 'head' )) {
          setCategoryView('settings')
          setORtask = true 
          fetchData();
          setUserWorkLevel(workCenter)
          setting = true
          
        } else if(btnSent==='task') {
          setCategoryView('task')
          fetchData()
          setORtask=true
          task = true
  
        }else if(btnSent === 'summery' & (userPosition === 'man' || userPosition === 'planner' || userPosition === 'head' || userPosition === 'eng'  )){
          setCategoryView('summery')
          setORtask=true
          fetchData()
          setUserWorkLevel(workCenter)
          summery = true
  
        }else if(btnSent === 'users' & (userPosition === 'man' || userPosition === 'planner' || userPosition === 'head' )){
          setCategoryView('users')
          user = true
  
        }else if(btnSent === 'history' & (userPosition === 'man' || userPosition === 'planner' || userPosition === 'head' || userPosition === 'eng'  )){
          setCategoryView('history')
          history = true
        }
  
        setPmlist(pmlist)
    
        if (db_machinlist) {
    
          if(userShopLevel === 'owner'){
            SetUniquePlant([...new Map(db_machinlist.map((machin)=>[machin.Plant, machin])).values()]);
            SetUniqueWorkCenter([...new Map(db_machinlist.map((machin)=>[machin.Work_Center, machin])).values()]);
            setBoxShowState('plant')
            setPlantView(1)
  
            var OperatorList =[...new Map(db_machinlist.filter((item)=>{
              return (workCenter == item.Work_Center) 
            }).map((machin)=>[machin.Operator_Name, machin])).values()];
          
          }else{
            if (history || setting || task || user || summery) {
              if (setORtask) {
                var punique=[...new Map(db_machinlist.filter((item)=>{
                  return (workCenter === item.Work_Center) 
                }).map((machin)=>[machin.Plant, machin])).values()];
                  
                SetUniqueWorkCenter([...new Map(db_machinlist.filter((item)=>{
                  return (workCenter === item.Work_Center)
                }).map((machin)=>[machin.Work_Center, machin])).values()]);
    
                SetUniqueOperatorList([...new Map(db_machinlist.filter((item)=>{
                  return (workCenter === item.Work_Center)
                }).map((machin)=>[machin.Operator_Name, machin])).values()]);
    
              } else {
                var punique=[...new Map(db_machinlist.filter((item)=>{
                  return (userWorkLevel.includes(item.Work_Center)) 
                }).map((machin)=>[machin.Plant, machin])).values()];
                  
                SetUniqueWorkCenter([...new Map(db_machinlist.filter((item)=>{
                  return (userWorkLevel.includes(item.Work_Center)) 
                }).map((machin)=>[machin.Work_Center, machin])).values()]);
              }
              if(punique.length>1){
                SetUniquePlant(punique)
                setBoxShowState('plant')
                setPlantView(1)
              }else if(punique.length==1){
                setPlantView(0)
                setWorkView(1)
                setBoxShowState('WorkCenterCategory')      
                setPlantName(punique[0].Plant_Name);
                setPlant(punique[0].Plant);
                setPageTitle(punique[0].Plant_Name +' / ');
              }else if(punique.length==0) {
                setBoxShowState('plant')
                SetUniquePlant(punique)
                setTaskHint(()=>{
                  return(
                    <div>
                      <div>فعالیتی برای گروه کاری شما تعریف نشده است1</div>
                      <div>
                        <button onClick={onBackButtonEvent} className='btn_cur'> بازگشت </button>
                      </div>
                    </div>
                  )
                })          
                SetUniqueShop()
              }        
              
            }
          }
    
          setAllMachinList(db_machinlist);
          // setBoxShowState('plant')
        } else {
          setBoxShowState('plant')
          setTaskHint(()=>{
            return(
              <div>
                <div>فعالیتی برای گروه کاری شما تعریف نشده است</div>
                <div>
                  <button onClick={onBackButtonEvent} className='btn_cur'> بازگشت </button>
                </div>
              </div>
            )
          })
          SetUniqueShop()
        }
    }


    //-----------------------------------------------------------------------------

  
    const btnHome = <span> خانه </span>
    const btnTask = <span>فعالیت های جاری</span>
    const btnHistory = <span>سوابق فعالیتها</span>
    const btnUsers = <span>لیست کاربران</span>
    const btnEducation = <span>مطالب آموزشی </span>
    const btnContactUS = <span> ارتباط با مدیریت</span>
    //---------------------------------------------------------
    const iconHome = <div> <HomeOutlinedIcon/> </div>
    const iconTask = <div> <PlaylistAddCheckOutlinedIcon/> </div>
    const iconHistory = <div> <ManageHistoryOutlinedIcon/> </div>
    const iconUsers = <div> <AccountBoxOutlinedIcon/> </div>
    const iconEducation = <div> <SchoolOutlinedIcon/> </div>
    const iconContactUS = <div> <ContactPhoneOutlinedIcon/> </div>
    var ArrBtnTxt = []
    var ArrBtnKey = []
    var ArrBtnIcon = []
    
    if (userPosition === 'man' || userPosition === 'planner' || userPosition === 'head' || userPosition === 'eng') {
      ArrBtnTxt = [btnHome , btnTask, btnHistory, btnUsers, btnEducation, btnContactUS]
      ArrBtnKey = ['', 'task', 'history', 'users', 'education', 'contactUS']
      ArrBtnIcon = [iconHome, iconTask, iconHistory, iconUsers, iconEducation, iconContactUS]
    } else if(userPosition === 'tech'){
      ArrBtnTxt = [btnHome , btnTask, btnEducation, btnContactUS]
      ArrBtnKey = ['','task', 'education', 'contactUS']
      ArrBtnIcon = [iconHome, iconTask, iconEducation, iconContactUS]
    }
    const btnMain = []
    var obj_btntxt = {}
  
  
    for (let index = 0; index < ArrBtnTxt.length; index++) {
      let constKey = ArrBtnKey[index]
      let constValue = ArrBtnTxt[index]
      let constIcon = ArrBtnIcon[index]
      obj_btntxt = {con:constKey, value:constValue, icon:constIcon}
      btnMain.push(obj_btntxt)
    }


    //-----------------------------------------------------------------------------

    const date = new DateObject({ calendar: persian, locale: persian_fa });

    return(
        <header onClick={menueClose}>
            <div className="menu">
                <div className='h_menu'>
                    {isLoggedin && 
                    <i onClick={ToggleClass} > {isActive?<MenuIcon/> : <MenuOpenIcon/> }</i>}
                </div>
                <div>
                    <NavLink to='/' className="logo">
                        <img src={ikco_logo} alt="Logo" />
                         سامانه ثبت نتایج فعالیتهای نت 
                    </NavLink>
                </div>
                {isLoggedin &&    
                <nav className={isActive ? "c_navbar" : "c_navbar active" } >
                  {btnMain.map((navCon)=>{
                    return(
                      <NavLink to={`/${navCon.con}`} onClick={()=>handler(navCon.con)}><div className='flex_center'> {navCon.icon} {navCon.value} </div></NavLink>
                    )
                  })}
                </nav>}
                <div className='user_id'>
                    <img className='id_img'
                        alt='person'
                        src = { non_user_logo}
                    />
                    <p className='id_name'>{fullName}</p>
                    <div>
                        {isLoggedin && <NavLink to='/login' className="logout" onClick={()=>do_logoutt()}> خروج</NavLink>}
                    </div>
                </div>
                </div>
                <div className='top-idicator'>
                <div className='path'>
                <h3> </h3>{!(boxShowState ==='plant') && <h3>{pageTitle}</h3>}
                </div>
                <div className='date_time'>
                <h3>{date.format("dddd")} {date.format()}</h3> 
                </div>
            </div>
            <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
              <div className="ring">
                <div className="spinner"></div>
              </div>
              <div className='modal_wait_Text'>
                  لطفا منتظر بمانید ...
              </div>
            </Modal>
        </header>

    )
}
export default Navbar;