import React, { useContext, useEffect, useRef, useState } from 'react';


import { userdata } from '../App';
import { useFetch } from "../hooks/useFetch"

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import './listcat.css'
import '../pages/Summery/summery.css'
import Modal from "./Modal";
import { useNavigate } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CircularProgress from '@mui/material/CircularProgress';
 
//-------------------------------------------------------------------

export default function Condition_table() {

    const [showModal,setShowModal] = useState(false);
    const [modalShop,setModalShop] = useState()
    const [modalIndex,setModalIndex] = useState()
    const [modSelect,setModSelect] = useState('s')
    const [machinItem,setMachinItem] = useState('')
    const [showSuccess,setShowSuccess] = useState(false)
    const [showFailed,setShowFailed] = useState(false)
    const navigate = useNavigate()
    const onBackButtonEvent = (e) => {
        // e.preventDefault();
          setBoxShowState('WorkCenterCategory') 
    }
    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList
        } = useContext(userdata)
    

    useEffect(()=>{
        setPageTitle(section)
        refresh()
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [])


    var plusORminus = 0;

    const [showWait,setShowWait] = useState(false)
    const [dataBack,setDataBack] = useState([])

//--------------------------------------------------------
    const modalClosed=()=>{
        setShowModal(false)
        setShowSuccess(false)
        setShowFailed(false)
    }

    var backResult = ''
    var backData = ''
    var wOfcurrent = ''
    var wfromstart = ''
    var Period_Arr = []
    

    var pmperiodTempW = '';
    var periodtext=''
    var lenPeriod = 0;

    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

    const pdate = new DateObject({ calendar: persian, locale: persian_fa});
    const pdate_1 = new DateObject({ calendar: persian, locale: persian_fa})
    const now = new DateObject({ calendar: persian, locale: persian_fa})

//--------------------------------------------------------
    async function postJSON(data) {
        try {
          const response = await fetch('https://rezasayyahi.ir/dbtest/conditionCheck.php', {
            method: "POST", // or 'PUT'
            headers: {
              'Accept' : 'applicaion/json',
              "Content-Type": "application/json",
            },
            body:JSON.stringify({
                    arr: data
                })
          });
      
          const result = await response.json();
          backResult = result.result
          backData = result.backData

          if (backResult == 'success') {
            setExecuted(true)
            conditionShow()
            
            setDataBack(backData)
            setShowWait(false)
          }else{
            setDataBack(backData)
            conditionShow()
            setShowWait(false)
          }
      
        } catch (error) {
            console.error("Error:", error);
            alert(error)
        }
      }
//--------------------------------------------------------
    const refresh = ()=>{
        setShowWait(true)
        const mArr = []
        var mobj={}
        mobj = {workCenter:workCenter, week:wOfcurrent , year:p2e(now.format('YYYY'))}
        mArr.push(mobj)
        postJSON(mArr)
    }
//--------------------------------------------------------
const machinClickHandle = (btn_shop,btn_shopName,btn_machin,btn_machinName,btn_stationName,
    btn_machiCounter,btn_period,btn_groupCode, dataBackin,operator,operatorName)=>{

    if (!(btn_shop === 'notex')) {
        console.log('Ex Clicked',btn_shop, btn_machiCounter, dataBackin)
        
        const lenPm = pmList.filter((pm) => {
            return ( pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & pm.Period === btn_period)
        })

        const respp = dataBackin.filter((data) => {
            return (data.Shop === btn_shop & data.Machin === btn_machin & data.Task_Group === btn_groupCode &
                  data.Period === btn_period & data.Counter === btn_machiCounter)
        })

        const mArr = []
        var mobj={}
        lenPm.map((mdata , mindex)=>{
          mobj = {taskCounter:mindex+1,taskGroup:mdata.Task_Group, workCenter:mdata.Work_Center, plant:mdata.Plant,
            shop: shop, machin: mdata.Machin, counter:btn_machiCounter, period:mdata.Period,operator:operator,
            operatorName:operatorName, operationText:mdata.Operation_Text, comment:'', result:'', date:'',
            year:String(p2e(pdate.format('YYYY'))), W_of_Y:wOfcurrent, startDate:startDate, W_from_Start:wfromstart}
          mArr.push(mobj)
        })
        setResult(mArr)
        setExecuted(true)

        if (operator === userNumber) {
            setIsDisabled(false)
          } else {
            setIsDisabled(true)
          }

        setShopName(btn_shopName)
        setShop(btn_shop)
        setMachinName(btn_machinName)
        setStationName(btn_stationName)
        setResponse(respp)
        setFinalPmlist(lenPm) 

        const sunique=[...new Map(allMachinList.filter((item)=>{
            return (item.Plant === plant & item.Work_Center === workCenter)
          }).map((machin)=>[machin.Shop, machin])).values()];
          SetUniqueShop(sunique);

        navigate('/task')
        setBoxShowState('pmlist')
    }
}

const ptype = useRef();

useEffect(()=>{
    for (let index = 0; index < uniqueOperatorList.length; index++) {
        ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[0].style.display = 'block'
        ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[1].style.display = 'none'
    }
})

const toggleplus = (index,idShopList,idShopCat)=>{
let plus_text = ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[0].style.display
if (plus_text == 'none' || plus_text == ' ') {
    ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[0].style.display = 'block'
    ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[1].style.display = 'none'
    document.getElementById(idShopList).style.display="none"
    document.getElementById(idShopCat).style.border=""
    document.getElementById(idShopCat).style.borderRadius=""
} else if (plus_text == 'block') {
    ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[0].style.display = 'none'
    ptype.current.childNodes[index].childNodes[0].childNodes[0].childNodes[0].childNodes[1].style.display = 'block'
    document.getElementById(idShopList).style.display="flex"
}

}


//--------------------------------------------------------
const condition_summery = (Operator)=>{
  var task_counter = 0
  let j = 0;

  allMachinList.map((mdata,mindex)=>{
    if (mdata.Operator === Operator & mdata.Work_Center === workCenter){
      const Arr_period = Period_Arr_creator(mdata.Start_Date)
      Arr_period.map((period_local)=>{
        if (dataBack.filter((item)=>{
          return (item.Operator === Operator)
        }).find((item2) => (item2.Shop === mdata.Shop & item2.Counter === mdata.Counter & item2.Period === period_local.period))){
          j++
      }

      })
      task_counter = task_counter + Arr_period.length
    }
  })
  var lenPeriod =  task_counter
  let percent = 0;

  percent = (j/(lenPeriod))*100
  return percent.toFixed(0);
}

//--------------------------------------------------------

const Period_Arr_creator=(start_date)=>{

  var Period_Arr_local = []

  var firstDay_txt = p2e(now.format('YYYY'))+'/01/01'

  p2e(pdate.setDate(firstDay_txt).format());
  var unixOffset = 0
  var startofyear_unix = 0

  if ((pdate.weekDay.number)>1){
    unixOffset = (7 - pdate.weekDay.number) + 1
    startofyear_unix = pdate.toUnix() + (unixOffset * 86400)
  }else{
    startofyear_unix = pdate.toUnix()
  }
  var currentDay_unix = now.toUnix()

  var diffDay = ((currentDay_unix - startofyear_unix) / 86400)

  var weekOfCurrent_unix = parseInt((diffDay / 7) + 1)

  p2e(pdate.setDate(start_date).format());
  var machinStartDate_unix = pdate.toUnix()

  var diffFromStart = ((currentDay_unix - machinStartDate_unix) / 86400)
  var weekFromStart_unix = parseInt((diffFromStart / 7) + 1)
  

  var firstDayOfstartYear = p2e(pdate.format('YYYY'))+'/01/01'
  p2e(pdate.setDate(firstDayOfstartYear).format());
  var weekFromStart_create_unix = pdate.toUnix()

  var diffStartToFirstDay = ((machinStartDate_unix - weekFromStart_create_unix) / 86400)
  var weekFromStartDay_unix = parseInt((diffStartToFirstDay / 7) + 1)
  
  wOfcurrent = weekOfCurrent_unix
  wfromstart = weekFromStart_unix

//   setWeekOfCurrent(Number(weekOfCurrent_unix))
//   setWeekFromStart(Number(weekFromStart_unix))

  const punique=[...new Map(pmList.filter((item)=>{
    return (item.Work_Center === workCenter )
  }).map((task)=>[task.Period, task])).values()];

  var Arr_puniqe = []
  punique.map((puni , index)=>{
    Arr_puniqe.push(puni.Period)
  })

  weekFromStart_unix -=3

  // if (weekFromStart_unix%26==0 & !(weekFromStart_unix==0)) {
  //   console.log('6M is Reached')
  //   pmperiodTemp = '6M'
  //   pmperiodTempW = '6M'
    
  //   const Arr =  []
  //   var obj={}
  //   var localtext=''
  //   punique.map((puni , index)=>{
  //     if (puni.Period === '6M') {
  //       localtext = 'شش ماهه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     } else if (puni.Period === '3M') {
  //       localtext = 'سه ماهه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     } else if (puni.Period === '1M') {
  //       localtext = 'ماهانه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     }else if (puni.Period === '1W'){
  //       localtext = 'هفتگی'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     }
  //   })
  //   console.log('Arr 6M= ',Arr)
  //   SetUniquePeriod(Arr)
  //   lenPeriod = Arr.length




  // } else if (weekFromStart_unix%15== 0 & !(weekFromStart_unix==0)) {


  if (weekFromStart_unix%12 == 0 & !(weekFromStart_unix == 0)) {

    pmperiodTempW = '3M'

    if (Arr_puniqe.includes('3M')){
      pmperiodTempW = '3M'
    }else if (Arr_puniqe.includes('1M')) {
      pmperiodTempW = '1M'
    }else if (Arr_puniqe.includes('1W')) {
      pmperiodTempW = '1W'
    }

    const Arr =  []
    var obj={}
    var localtext=''
    punique.map((puni , index)=>{
      if (puni.Period === '3M') {
        localtext = 'سه ماهه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      } else if (puni.Period === '1M') {
        localtext = 'ماهانه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }else if (puni.Period === '1W'){
        localtext = 'هفتگی'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }
    })
    Period_Arr_local = Arr
    // SetUniquePeriod(Arr)
    lenPeriod = Arr.length

  }else if (weekFromStart_unix%4 == 0 & !(weekFromStart_unix == 0)){
    pmperiodTempW = '1M'

    if (Arr_puniqe.includes('1M')) {
      pmperiodTempW = '1M'
    }else if (Arr_puniqe.includes('1W')) {
      pmperiodTempW = '1W'
    }
    const Arr =  []
    var obj={}
    var localtext=''
    punique.map((puni , index)=>{
      if (puni.Period === '1M') {
        localtext = 'ماهانه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }else if (puni.Period === '1W'){
        localtext = 'هفتگی'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }
      periodtext = localtext
    })
    Period_Arr_local = Arr
    // SetUniquePeriod(Arr)
    lenPeriod = Arr.length

  }else{
    if (Arr_puniqe.includes('1W')) {
      pmperiodTempW = '1W'
      const Arr =  []
      var obj={}
      var localtext=''
      var Arr_puniqe = []
      punique.map((puni , index)=>{
        if (puni.Period === '1W'){
          localtext = 'هفتگی'
          obj = { period: puni.Period , text: localtext}
          Arr.push(obj)
        }
        periodtext = localtext
      })
      Period_Arr_local = Arr
    //   SetUniquePeriod(Arr)
      lenPeriod = Arr.length
    }else{
        pmperiodTempW = 'NAN'
        const Arr =  []
        var obj={}
        obj = { period: 'NAN' , text: 'NAN'}
        Arr.push(obj)

        Period_Arr_local = Arr
    }
  }
  return Period_Arr_local

}


//--------------------------------------------------------
    const conditionShow = ()=>{
        
        return(
            < >
                <div className='ass_h_cat'>
                    <div className='ass_h_sname'>
                        <h4 >
                            نام مجری
                        </h4>
                    </div>
                    <div className='ass_h_name'>
                        <h4>وضعیت اجرا </h4>                               
                    </div>
                </div>
                <div className= 'summ_item_con' ref={ptype}>
                    {uniqueOperatorList.map((data , index)=> {
                    let idShopCat = `shopcat${index+1}`;              
                    let idShopList = `list${index+1}`;
                    let idPlustext = `plusText${index+1}`;
                    
                    var i= 0;
                    var percent;
                    return(
                        <div className='sum_con' key={index}>
                            <div className='summ_cat' id={idShopCat} onClick={()=>toggleplus(index ,idShopList,idShopCat)}>
                                <div className='summ_op_name' >
                                    <div className='pluss' >
                                        <div>
                                            <AddBoxOutlinedIcon />
                                        </div>
                                        <div>
                                            <IndeterminateCheckBoxOutlinedIcon />
                                        </div>
                                    </div>
                                    <a>{data.Operator_Name}</a>
                                </div>
                                <div style={{color: condition_summery(data.Operator)<=50 ? 'var(--red)':'var(--box-txt-ins)'}}>
                                    {condition_summery(data.Operator)}%
                                </div>
                                <div className='progress_con' >
                                    <div className='progress_bar' style={{ width:`${condition_summery(data.Operator)}%`}}>
                                    </div>
                                </div>
                            </div>
                            <div className='ass_shoplist' id={idShopList}>
                                {allMachinList.map((ldata , lindex)=> {
                                    if (ldata.Operator === data.Operator & ldata.Work_Center === workCenter) {
                                        i++;
                                        return(
                                            <div key={lindex} className='ass_shopitem'>
                                                <div className='ass_shop_machin'>
                                                    <h2 className='m_item'>{(i)+ ' - '} سالن {ldata.Shop_Name} - {ldata.Machin_Name}</h2>
                                                    <h2 className='st_item_summ'>({ldata.Station_Name} {!(ldata.Station =='') && (' اا '+ (ldata.Station))})</h2>
                                                </div>
                                                <h1>
                                                    {condition_period(ldata.Start_Date, ldata.Shop,ldata.Shop_Name,data.Operator,data.Operator_Name,
                                                    ldata.Machin,ldata.Machin_Name,ldata.Station_Name, ldata.Counter,ldata.Group_Code, dataBack)}
                                                </h1>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>)
                    })}
                </div>
                
        </>
        )
    }
//--------------------------------------------------------

    const condition_period = (startDate, shopp,shopName,operator,operatorName,machin,machinName,stationName, machiCounter,groupCode, dataBackin)=>{
      const Arr_period = Period_Arr_creator(startDate)

        return(
            <div className='summ_op_scop'>
                {Arr_period.map((period, index)=>{
                    if (period.period != 'NAN') {
                        return(
                                <div key={index} className='summ_condition' 
                                    onClick= {(dataBackin.find((data) => (data.Shop == shopp & data.Counter == machiCounter & data.Period == period.period))) ?
                                        (()=>machinClickHandle(shopp,shopName, machin,machinName,stationName, machiCounter,period.period,groupCode, dataBackin,operator,operatorName)):
                                    (()=>machinClickHandle('notex', 'notex', 'notex'))}>
                                    <h2>{period.text}</h2>
                                    <h3 className='op_item_summ'>
                                        {(dataBackin.find((data) => (data.Shop == shopp & data.Counter == machiCounter & data.Period == period.period))) ?
                                            (<div className =  'ex' > ✔ </div>) :
                                        <div className =  'nex' > ❌</div>}
                                    </h3>
                                </div>
                        )
                    }
                })}
            </div>
        )
    }

//--------------------------------------------------------


    return (
        <div className='summery_mainDiv'>
            <div className='title_con'>
                <h4 className='title' >وضعیت اجرای فعالیت ها در هفته جاری</h4>
            </div>
            {conditionShow(backData)}
            <button onClick={()=>refresh()} className='btn_conf stikbottom'> بروزرسانی </button>

            <div className="mod_con">
            <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
                <div className="ring">
                <div className="spinner"></div>
                </div>
                <div className='modal_wait_Text'>
                    لطفا منتظر بمانید ...
                </div>
            </Modal>
            </div>
        </div>
  )
}
