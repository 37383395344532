import { useContext, useEffect, useState } from 'react';
import { userdata } from '../App';
import './listcat.css'
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import DatePicker, { DateObject } from "react-multi-date-picker"

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import Modal from './Modal'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';


export default function PeriodCategory(props) {
  var commentArray = []
    const navigate = useNavigate()

    const onBackButtonEvent = (e) => {
      if (props.clscon === 'btncon') {
        e.preventDefault();
        setBoxShowState('machin');
        // navigate('/task')
      }
    }

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
    pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
    uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment
    } = useContext(userdata)

  useEffect(()=>{
    // setPageTitle(shopName + ' / '+ machinName)
    setPageTitle(shopName +' / '+ machinName +' - '+ sname);
    if (props.clscon === 'btncon') {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
      };
    }
    }, [])

    const [showWait,setShowWait] = useState(false)
    const [showNoTask,setShowNoTask] = useState(false)

    const pdate = new DateObject({ calendar: persian, locale: persian_fa})

    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

    const modalClosed=()=>{
      setShowNoTask(false)
      setShowWait(false)
      // if (lenMachin==1) {
      //   onBackButtonEvent()
      // }
  }

//.......................... Fetch Data Satat .............................

async function postJSON(data,lenPm) {
  try {
    const response = await fetch('https://rezasayyahi.ir/dbtest/resultCheck.php', {
      method: "POST", // or 'PUT'
      headers: {
        'Accept' : 'applicaion/json',
        "Content-Type": "application/json",
      },
      body:JSON.stringify({
        arr: data
        // ,type:'pmlist'
      })
    })

    const result = await response.json()

    setIsOperator(userNumber);
    setIsOperatorName(fullName);

    if (result.operator === userNumber) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }


    console.log('history = ',result.history)
    result.history.map((comment , index)=>{
      commentArray.push(comment.Comment)
    })
    setHistoryComment(commentArray.filter((value, index, array) => array.indexOf(value) === index))

    if (result.result === 'fail') {
      setShowWait(true)
      setExecuted(false)

      if (lenPm.length > 0) {
        setBoxShowState('pmlist')
        setShowWait(false)
        setPmCat(1);
      }else{
        setShowNoTask(true)
        if (boxShowState == 'pmlist') {
          setBoxShowState('machin')
        }        
      }
    }else{
      setShowWait(true)
      setExecuted(true)
      setResponse(result.result)
      setShowWait(false)
      setBoxShowState('pmlist')
      setPmCat(1);
    }
    
  } catch (error) {
      console.error("Error1:", error);
      alert(error)
    }
}

//............................ Fetch Data End ..............................


  const itemSelect = (btn_period , btn_text)=>{
    
    const lenPm = pmList.filter((pm) => {
      return (pm.Machin === stype & pm.Task_Group === groupCode & pm.Period === btn_period)
    })
    
    setFinalPmlist(lenPm)
    setPmPeriod(btn_period);
    
    const mArr = []
    var mobj={}
    lenPm.map((mdata , mindex)=>{
      mobj = {taskCounter:mindex+1, taskGroup:mdata.Task_Group, workCenter:mdata.Work_Center, plant:mdata.Plant,
        shop: shop, machin: mdata.Machin, counter:machinCounter, period:btn_period, operator:userNumber,
        operatorName:fullName, operationText:mdata.Operation_Text, comment:'',result:'',date:'',
        year:String(p2e(pdate.format('YYYY'))),W_of_Y:weekOfCurrent, startDate:startDate, W_from_Start:Number(weekFromStart)}
      mArr.push(mobj)
    })
// console.log('mArr = ',mArr)
    postJSON(mArr,lenPm)
    setResult(mArr)
    setShowWait(true)

    setPageTitle(shopName + ' / '+ machinName + ' - '+ sname + ' ('+btn_text+')')
  }

  return (
    <div className='mainDiv1'>
      <div>
        <h4>Period Selection</h4>
        <br/>
      </div>
      <div className={props.clscon}>
        {uniquePeriod.map((data , index)=> {
            return(
                <div key={index} className={props.clscat} 
                    onClick={()=>itemSelect(data.period , data.text)}>
                    {data.text}
                </div>
            )
        })}
      </div>
      <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
        <div className="ring">
          <div className="spinner"></div>
        </div>
        <div className='modal_wait_Text'>
            لطفا منتظر بمانید ...
        </div>
      </Modal>
      <Modal show={showNoTask} modalClosed={modalClosed} className="hintModal">
          <div className='mod_closeCon'>
              <i onClick={modalClosed} className="close fas fa-window-close"></i>
              <ErrorOutlineOutlinedIcon style={{color:`var(--red)`, fontSize:'2rem'}}/>
          </div>
          <div className='hintModal_text' style={{border:`1px solid var(--red)`}}>
              <h4>در هفته جاری فعالیتی برای ماشین فوق تعریف نشده است</h4>
          </div>
        </Modal>
    </div>
  )
}
