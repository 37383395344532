 
import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { useNavigate } from 'react-router-dom'
import { CleaningServices } from '@mui/icons-material';
import Modal from "./Modal"


export default function PeriodSelector(props) {

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear
    } = useContext(userdata)

    
    const navigate = useNavigate()

    useEffect(()=>{
        setHis_filterd_Btn("1W")

        let temp_res = res_filteres_by_machin.filter((filterItem)=>{
            return(filterItem.Period === '1W')
        })
        setResponse(temp_res)

        let temp_pm = pm_filteres_by_machin.filter((filterItem)=>{
            return(filterItem.Period === '1W')
        })
        setFinalPmlist(temp_pm)
        
    }, [])

    const modalClosed=()=>{
    }

    const [showWait,setShowWait] = useState(false)
//--------------------------------------------------------

    const itemSelect = (btn_period , btn_text)=>{
        
        setHis_filterd_Btn(btn_period)

        let temp_res = res_filteres_by_machin.filter((filterItem)=>{
            return(filterItem.Period === btn_period)
        })
        setResponse(temp_res)
        // console.log('temp_res = ', temp_res)

        let temp_pm = pm_filteres_by_machin.filter((filterItem)=>{
            return(filterItem.Period === btn_period)
        })
        setFinalPmlist(temp_pm)
        console.log('temp_pm = ', temp_pm)

        setShowWait(true)
        setTimeout(()=>setShowWait(false),500)
    }

    const showList=()=> {

        return(
            <>
                <div className='period_filter_con'>
                    <div className='period_filter_cat'>
                        {uniquePeriod.map((data , index)=> {
                            return(
                                <div key={index} className={his_filterd_Btn === data.period ?'period_filter_item btn_active' :
                                'period_filter_item'} onClick={()=>itemSelect(data.period , data.text)}>
                                    {data.text}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }

  return (
    <div>
        {showList()}
        <div className="mod_con">
        <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
            <div className="ring">
            <div className="spinner"></div>
            </div>
            <div className='modal_wait_Text'>
                لطفا منتظر بمانید ...
            </div>
        </Modal>
      </div>
    </div>
    )
}
