import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import Modal from './Modal'

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

export default function MachinList(props) {
  var tempresponse='';
    const navigate = useNavigate()
    var wOfcurrent = ''
    var wfromstart = ''
    var commentArray = []

    const [showWait,setShowWait] = useState(false)
    const [showNoTask,setShowNoTask] = useState(false)

    // const {data : resp, isLoading:Loading, error:Error} = useFetch2(url,sent);

    const onBackButtonEvent = (e) => {
      // console.log('props.clscon - machin - back= ', props.clscon )

      if (props.clscon === 'btncon') {
        // e.preventDefault();
        setBoxShowState('shop')
        setTaskHint('')
      }
    }

    const modalClosed=()=>{
      setShowWait(false)
      setBoxShowState('machin')
      setShowNoTask(false)
      if (lenMachin==1) {
        onBackButtonEvent()
      }
    }

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
      setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
      machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
      stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
      boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
      uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
      userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
      setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
      workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
      shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
      isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
      weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
      executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
      pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
      uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
      his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
      historyyear, SetHistoryyear
      } = useContext(userdata)

    useEffect(()=>{
      // if (categoryView === 'history') {
      //   setResponse(h_Response)
      //   setPageTitle(' سوابق فعالیتهای هفته ' +historyWeek + ' سالن ' +shopName)
      // }
      
      // if (props.clscon === 'btncon') {
      //   window.history.pushState(null, null, window.location.pathname);
      //   window.addEventListener('popstate', onBackButtonEvent);
      //   return () => {
      //     window.removeEventListener('popstate', onBackButtonEvent);  
      //   }
      // }
    }, [])

    const lenMachin = allMachinList.filter((item)=>{
      return (item.Plant === plant & item.Shop === shop & item.Work_Center === workCenter)
      }).length

      const pdate = new DateObject({ calendar: persian, locale: persian_fa})
      const pdate_1 = new DateObject({ calendar: persian, locale: persian_fa})
      const now = new DateObject({ calendar: persian, locale: persian_fa})
    var pmperiodTemp = '';
    var pmperiodTempW = '';
    var lenPeriod = 0;
    var periodtext=''

    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));



//........................  Week calc Start.................................

const dateDiff = (startDate)=>{
  console.log('startDate = ',startDate)
  
  var firstDay_txt = p2e(pdate.format('YYYY'))+'/01/01'

  p2e(pdate.setDate(firstDay_txt).format());
  var unixOffset
  var startofyear_unix

  if ((pdate.weekDay.number)>1){
    unixOffset = (7 - pdate.weekDay.number) + 1
    startofyear_unix = pdate.toUnix() + (unixOffset * 86400)
  }else{
    startofyear_unix = pdate.toUnix()
  }
  var currentDay_unix = now.toUnix()

  var diffDay = ((currentDay_unix - startofyear_unix) / 86400)

  var weekOfCurrent_unix = parseInt((diffDay / 7) + 1)
  // console.log('weekOfCurrent_unix = ', weekOfCurrent_unix)

  p2e(pdate.setDate(startDate).format());
  var machinStartDate_unix = pdate.toUnix()

  var diffFromStart = ((currentDay_unix - machinStartDate_unix) / 86400)
  var weekFromStart_unix = parseInt((diffFromStart / 7) + 1)
  // console.log('weekFromStart_unix = ', weekFromStart_unix)
  // console.log('weekFromStart_unix_type = ', weekFromStart_unix/12)
  

  var firstDayOfstartYear = p2e(pdate.format('YYYY'))+'/01/01'
  p2e(pdate.setDate(firstDayOfstartYear).format());
  var weekFromStart_create_unix = pdate.toUnix()

  var diffStartToFirstDay = ((machinStartDate_unix - weekFromStart_create_unix) / 86400)
  var weekFromStartDay_unix = parseInt((diffStartToFirstDay / 7) + 1)
  console.log('weekFromStartDay_unix = ', weekFromStartDay_unix)

  
  console.log('weekOfCurrent_unix = ' , weekOfCurrent_unix)
  console.log('weekFromStart_unix = ' , weekFromStart_unix)
  
  wOfcurrent = weekOfCurrent_unix
  wfromstart = weekFromStart_unix

  setWeekOfCurrent(Number(weekOfCurrent_unix))
  setWeekFromStart(Number(weekFromStart_unix))


  // console.log('weekFromStart_unix%12 __ First = ',weekFromStart_unix%12)

  const punique=[...new Map(pmList.filter((item)=>{
    return (item.Work_Center === workCenter )
  }).map((task)=>[task.Period, task])).values()];
  
  // console.log('punique_AllPmList = ', punique)

  var Arr_puniqe = []
  punique.map((puni , index)=>{
    Arr_puniqe.push(puni.Period)
  })
  // console.log('Arr_puniqe = ', Arr_puniqe)

  weekFromStart_unix -=3

  // if (weekFromStart_unix%26==0 & !(weekFromStart_unix==0)) {
  //   console.log('6M is Reached')
  //   pmperiodTemp = '6M'
  //   pmperiodTempW = '6M'
    
  //   const Arr =  []
  //   var obj={}
  //   var localtext=''
  //   punique.map((puni , index)=>{
  //     if (puni.Period === '6M') {
  //       localtext = 'شش ماهه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     } else if (puni.Period === '3M') {
  //       localtext = 'سه ماهه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     } else if (puni.Period === '1M') {
  //       localtext = 'ماهانه'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     }else if (puni.Period === '1W'){
  //       localtext = 'هفتگی'
  //       obj = { period: puni.Period , text: localtext}
  //       Arr.push(obj)
  //     }
  //   })
  //   console.log('Arr 6M= ',Arr)
  //   SetUniquePeriod(Arr)
  //   lenPeriod = Arr.length




  // } else if (weekFromStart_unix%13== 0 & !(weekFromStart_unix==0)) {


  if (weekFromStart_unix%12 == 0 & !(weekFromStart_unix == 0)) {
    // console.log('33333333333M is Reached')

    // pmperiodTemp = '3M'
    pmperiodTempW = '3M'

    if (Arr_puniqe.includes('3M')){
      pmperiodTempW = '3M'
    }else if (Arr_puniqe.includes('1M')) {
      pmperiodTempW = '1M'
    }else if (Arr_puniqe.includes('1W')) {
      pmperiodTempW = '1W'
    }

    const Arr =  []
    var obj={}
    var localtext=''
    punique.map((puni , index)=>{
      if (puni.Period === '3M') {
        localtext = 'سه ماهه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      } else if (puni.Period === '1M') {
        localtext = 'ماهانه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }else if (puni.Period === '1W'){
        localtext = 'هفتگی'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }
    })
    console.log('Arr 3M= ',Arr)
    SetUniquePeriod(Arr)
    lenPeriod = Arr.length

  }else if (weekFromStart_unix%4==0 & !(weekFromStart_unix==0)){
    // pmperiodTemp = '1M'
    pmperiodTempW = '1M'

    if (Arr_puniqe.includes('1M')) {
      pmperiodTempW = '1M'
    }else if (Arr_puniqe.includes('1W')) {
      pmperiodTempW = '1W'
    }
    
    const Arr =  []
    var obj={}
    var localtext=''
    punique.map((puni , index)=>{
      if (puni.Period === '1M') {
        localtext = 'ماهانه'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }else if (puni.Period === '1W'){
        localtext = 'هفتگی'
        obj = { period: puni.Period , text: localtext}
        Arr.push(obj)
      }
      periodtext = localtext
    })
    console.log('Arr 1M= ',Arr)
    SetUniquePeriod(Arr)
    lenPeriod = Arr.length

  }else{
    console.log('peiod is else')

    if (Arr_puniqe.includes('1W')) {
      console.log('1W is period ***********************')
      pmperiodTempW = '1W'
      const Arr =  []
      var obj={}
      var localtext=''
      var Arr_puniqe = []
      punique.map((puni , index)=>{
        if (puni.Period === '1W'){
          localtext = 'هفتگی'
          obj = { period: puni.Period , text: localtext}
          Arr.push(obj)
        }
        periodtext = localtext
      })
      console.log('Arr 1W= ',Arr)
      SetUniquePeriod(Arr)
      lenPeriod = Arr.length
    }else{
      pmperiodTempW = 'NAN'
    }
  }
}

//........................  Week calc End .................................

 
//.......................... Fetch Data Satat .............................

async function postJSON(data,lenPm) {
  try {
    const response = await fetch('https://rezasayyahi.ir/dbtest/resultCheck.php', {
      method: "POST", // or 'PUT'
      headers: {
        'Accept' : 'applicaion/json',
        "Content-Type": "application/json",
      },
      body:JSON.stringify({
        arr: data,
        type:'pmlist'
      })
    })

    const result = await response.json()

    setIsOperator(result.operator);
    setIsOperatorName(result.operatorName);
    // setShowWait(false)
    if (result.operator === userNumber) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
    
    if (result.result === 'fail') {
      result.history.map((comment , index)=>{
        commentArray.push(comment.Comment)
      })
      setHistoryComment(commentArray.filter((value, index, array) => array.indexOf(value) === index))

      setPmRender('1')
      setShowWait(true)
      setExecuted(false)
      // console.log("fail:", result);

      if (lenPm.length > 0) {
        setBoxShowState('pmlist')
        setShowWait(false)
        setPmCat(0);
      }else{
        setShowNoTask(true)
        setShowWait(false)
        if (boxShowState == 'pmlist') {
          // setShowNoTask(true)
          // setBoxShowState('machin')
        }
      }
    }else{ 
      setShowWait(true)
      setExecuted(true)
      // console.log("Success:", result);
      setResponse(result.result)
      setShowWait(false)
      setBoxShowState('pmlist')
      setPmCat(0);
    }
  } catch (error) {
      // console.error("Error1:", error);
      alert(error)
    }
}

const update_Respons =(result)=>{
  setResponse(result)
  setShowWait(false)
}

//............................ Fetch Data End ..............................


  const itemSelect = (btn_machin ,btn_machinName , btn_station, btn_stationName, btn_groupCode, btn_startDate,
                        btn_operator, btn_operatorName,btn_counter)=>{
      
      const operator = btn_operator
      const operatorName = btn_operatorName

      const startDate = btn_startDate

      setStartDate(btn_startDate)
      setMachinName(btn_machinName)
      setStype(btn_machin)
      setSname(btn_station)
      setGroupCode(btn_groupCode)
      setStationName(btn_stationName)
      setMachinCounter(btn_counter)

      //............................................

      if (categoryView === 'history') {

        if (btn_operator === userNumber) {
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }

        setShowWait(true)
        setHis_filterd_Btn("1W")

        var h_res_filtered = response.filter((item)=>{
          return(item.Plant === plant & item.Shop === shop & item.Work_Center === workCenter & item.Counter === btn_counter & 
            item.Week_Of_Year === historyWeek)
        })

        setResponse(h_res_filtered)
        setRes_filteres_by_machin(h_res_filtered)
        
        // console.log('response_filtered = ' , h_res_filtered)

        var sixm = 0
        var threem = 0
        var onem = 0
        var onew = 0
        var periodCondition
        const Arr =  []
        var obj={}
        var lenPm

        lenPm = pmList.filter((pm) => {
          return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & pm.Period === '1W')
        })

        h_res_filtered.map((record)=>{
          if (record.Period === '6M') {
            sixm ++
          }else if(record.Period === '3M') {
            threem ++
          }else if(record.Period === '1M') {
            onem ++
          }else if(record.Period === '1W') {
            onew ++
          }
        })
        // console.log('6M = ', sixm , ' - 3M = ', threem , ' - 1M = ', onem , ' - 1W = ', onew)
        if (sixm > 0) {
          periodCondition = '6M'
          obj = { period: '1W' , text: 'هفتگی'}
          Arr.push(obj)
          obj = { period: '1M' , text: 'ماهانه'}
          Arr.push(obj)
          obj = { period: '3M' , text: 'سه ماهه'}
          Arr.push(obj)
          obj = { period: '6M' , text: 'شش ماهه'}
          Arr.push(obj)

          lenPm = pmList.filter((pm) => {
            return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & (pm.Period === '1W' || pm.Period === '1M' ||
             pm.Period === '3M' || pm.Period === '6M') )
          })

        } else if(threem > 0) {
          periodCondition = '3M'
          obj = { period: '1W' , text: 'هفتگی'}
          Arr.push(obj)
          obj = { period: '1M' , text: 'ماهانه'}
          Arr.push(obj)
          obj = { period: '3M' , text: 'سه ماهه'}
          Arr.push(obj)

          lenPm = pmList.filter((pm) => {
            return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & (pm.Period === '1W' || pm.Period === '1M' || pm.Period === '3M') )
          })

        } else if(onem > 0) {
          periodCondition = '1M'
          obj = {period: '1W' , text: 'هفتگی'}
          Arr.push(obj)
          obj = { period: '1M' , text: 'ماهانه'}
          Arr.push(obj)

          lenPm = pmList.filter((pm) => {
            return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & (pm.Period === '1W' || pm.Period === '1M'))
          })

        } else if(onew > 0) {
          periodCondition = '1W'
          obj = { period: '1W' , text: 'هفتگی'}
          Arr.push(obj)

          lenPm = pmList.filter((pm) => {
            return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & pm.Period === '1W')
          })
        }

        // console.log('Arr === ', Arr , ' - periodCondition = ', periodCondition )

        SetUniquePeriod(Arr)
        lenPeriod = Arr.length
        setFinalPmlist(lenPm)
        setPm_filteres_by_machin(lenPm)

        const mArr = []
        var mobj={}
        lenPm.map((mdata , mindex)=>{
          mobj = {taskCounter:mindex+1,taskGroup:mdata.Task_Group, workCenter:mdata.Work_Center, plant:mdata.Plant,
            shop: shop, machin: mdata.Machin, counter:btn_counter, period:mdata.Period,operator:operator,
            operatorName:operatorName, operationText:mdata.Operation_Text, comment:'', result:'', date:'',
            year:String(p2e(pdate.format('YYYY'))), W_of_Y:wOfcurrent, startDate:startDate, W_from_Start:wfromstart}
          mArr.push(mobj)
        })
        setResult(mArr)

        setBoxShowState('pmlist')
        setShowWait(false)

        setPageTitle(shopName +' / '+ btn_machinName +' - '+ btn_station +' سوابق هفته '+historyWeek)

      } else {

        dateDiff(btn_startDate)

        const lenPm = pmList.filter((pm) => {
          return (pm.Machin === btn_machin & pm.Task_Group === btn_groupCode & pm.Period === pmperiodTempW)
        })

        setFinalPmlist(lenPm)

        if (lenPeriod === 1) {
          const mArr = []
          var mobj={}
          lenPm.map((mdata , mindex)=>{
            mobj = {taskCounter:mindex+1,taskGroup:mdata.Task_Group, workCenter:mdata.Work_Center, plant:mdata.Plant,
              shop: shop, machin: mdata.Machin, counter:btn_counter, period:mdata.Period,operator:operator,
              operatorName:operatorName, operationText:mdata.Operation_Text, comment:'', result:'', date:'',
              year:String(p2e(pdate.format('YYYY'))), W_of_Y:wOfcurrent, startDate:startDate, W_from_Start:wfromstart}
            mArr.push(mobj)
          })

        // ...... Result Check Satart .........

          postJSON(mArr,lenPm)
          setResult(mArr)
          setShowWait(true)

        // ...... Result Check End ............

          setPageTitle(shopName +' / '+ btn_machinName +' - '+ btn_station +' ('+periodtext+')');


        } else {
          
          setBoxShowState('PeriodCategory');
          setPageTitle(shopName +' / '+ btn_machinName +' - '+ btn_station);
        }
    }
  }
  const showList = (class1 , class2)=>{
    var operator_text = categoryView
    // console.log('back_Response = ' , response)
        
    return(
      <div className={class1}>
      {allMachinList.filter((item)=>{
        return (item.Plant === plant & item.Shop === shop & item.Work_Center === workCenter)
        }).map((data , index)=> {

          var local_operator_name = response.filter((record)=>{
            return(record.Shop === shop & record.Counter === data.Counter & record.Work_Center === workCenter)
          })
          var local_operator_nameT
          if (local_operator_name.length != 0 ){
            local_operator_nameT =  local_operator_name[0].Operator_Name
          } 

          return(
            <div key={index} className={class2}
              onClick={()=>itemSelect(data.Machin ,data.Machin_Name, data.Station, data.Station_Name,
                data.Group_Code, data.Start_Date, data.Operator, data.Operator_Name, data.Counter)}>
              {data.Machin_Name}
              <br/>
              <br/>
              <h6>{data.Station_Name}</h6>
              <h4>{data.Station}</h4>
              {!(data.Station) && <h3> - </h3>}
              {operator_text === 'task' ? 
                <h6 style={{ borderTop:"1px solid var(--black)" , paddingTop:'1rem'}}>مجری : {data.Operator_Name}</h6> :
                <h6 style={{ borderTop:"1px solid var(--black)" , paddingTop:'1rem'}}>مجری : {local_operator_nameT}</h6>
              }

            </div>
          )
        })}
      </div>
    )
}
 
  return (
    <div>
        <div>
          <h4>لیست ماشین ها</h4>            
        </div>
        {showList(props.clscon , props.clscat)}

        <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
          <div className="ring">
            <div className="spinner"></div>
          </div>
          <div className='modal_wait_Text'>
              لطفا منتظر بمانید ...
          </div>
        </Modal>
        <Modal show={showNoTask} modalClosed={modalClosed} className="Modal">
          <div>
              <i onClick={modalClosed} className="close fas fa-window-close"></i>
              <br/>
              <br/>
          </div>
          <div className='modalText'>
              <h1>در هفته جاری فعالیتی برای گروه کاری شما تعریف نشده است</h1>
              <br/>
              <br/>
              <div>
                <br/>
                <br/>
                <br/>
              </div>
              <br/>
              <br/>
          </div>
        </Modal>
    </div>
    )
}
