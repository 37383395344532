
import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { useNavigate } from 'react-router-dom';

export default function PlantList(props) {
  
  const navigate = useNavigate();

  const onBackButtonEvent = (e) => {
    if (props.clscon === 'btncon') {
      e.preventDefault();
      setBoxShowState('');
      navigate('/');
    }
  }

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList
    } = useContext(userdata)
  
  useEffect(()=>{
    if (props.clscon === 'btncon') {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
      };
    }
  }, [])
  
  const itemSelect = (btn_plantName ,btn_plant)=>{
    if(userShopLevel==='owner' || userShopLevel==='planner'){
      // console.log('allMachinList = ', allMachinList)
      SetUniqueShop([...new Map(allMachinList.filter((item)=>{
        return (item.Plant === btn_plant )
      }).map((machin)=>[machin.Shop, machin])).values()]);

      SetUniqueOperatorList([...new Map(allMachinList.filter((item)=>{
        return (workCenter == item.Work_Center)
      }).map((machin)=>[machin.Operator_Name, machin])).values()]);

    }else{
      SetUniqueShop([...new Map(allMachinList.filter((item)=>{
        return (item.Plant === btn_plant && item.Work_Center === workCenter)
      }).map((machin)=>[machin.Shop, machin])).values()]);

      SetUniqueOperatorList([...new Map(allMachinList.filter((item)=>{
        return (item.Plant === btn_plant & workCenter === item.Work_Center)
      }).map((machin)=>[machin.Operator_Name, machin])).values()]);
      
    }
    
    setPlantName(btn_plantName)
    setPlant(btn_plant)
    setBoxShowState('WorkCenterCategory')
    setPageTitle(btn_plantName +' / ') 
  }

  const date = new DateObject({ calendar: persian, locale: persian_fa });

  const showList = (class1 , class2)=>{

    return(
          <div className={class1}>
            {uniquePlant.map((data , index)=> {         
            return(
              <div key={index} className={class2}
                  onClick={()=>itemSelect(data.Plant_Name , data.Plant)}>
                  {data.Plant_Name}
              </div>
              )
            })}
          </div>
    )
  }

  return (
    <div className='mainDiv1'>
      <div>
          <h4>لیست سایت ها</h4>
          <br/>
          <h1>{taskHint} </h1>
      </div>
      {showList(props.clscon , props.clscat)}
    </div>
  )
}
