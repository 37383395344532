import React, { useContext, useEffect, useState } from 'react';

import { userdata } from '../App';

import { Dropdown, DropdownButton } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css'
import './listcat.css'
import Modal from "./Modal";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';


export default function UserAssign() {

    const [showModal,setShowModal] = useState(false);
    const [modalShop,setModalShop] = useState()
    const [modalIndex,setModalIndex] = useState()
    const [modSelect,setModSelect] = useState('s')
    const [machinItem,setMachinItem] = useState('')
    const [showSuccess,setShowSuccess] = useState(false)
    const [showFailed,setShowFailed] = useState(false)

    const onBackButtonEvent = (e) => {
        // e.preventDefault();
          setBoxShowState('WorkCenterCategory') 
    }
    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted
        } = useContext(userdata)
    
    useEffect(()=>{
    // setPageTitle(plantName);

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
    };
    }, [])

    var shopOp = [];

    const selHandle =(sList,index,btn_shop)=>{
        setModalIndex(index)
        setModSelect('s')
        setModalShop(btn_shop)
        setShowModal(true)
    }

    const itemSelect = (mList,item, index, btn_machin, btn_shop) => {
        console.log('shop: ' , btn_shop)
        setMachinItem(item)
        setModalIndex(index)
        setModSelect('m')
        setModalShop(btn_shop)
        setShowModal(true)
    }

    const modalClosed=()=>{
        setShowModal(false)
        setShowSuccess(false)
        setShowFailed(false)
    }

    const modalSelect = (machinitem,modindex, btn_shop, btn_number, btn_full_name)=>{
        if (modSelect =='s'){
            shopOpin[modindex].operator = btn_full_name
            machinOpin.map((mdata , mindex)=>{
                if (mdata.shop === btn_shop & mdata.workCenter === workCenter) {
                    machinOpin[mindex].operatorName = btn_full_name
                    machinOpin[mindex].operator = btn_number
                }
              })
        }else if(modSelect =='m'){
            machinOpin[(machinitem-1)].operatorName = btn_full_name
            machinOpin[(machinitem-1)].operator = btn_number
        }
    setShowModal(false)
    
    }

    const plusClick = (idPlustext, idShopList,idShopCat)=>{

        var shopList = document.getElementById(idShopList).style.display

        if (shopList === "none" || shopList === "" ) {
            document.getElementById(idShopList).style.display="block"
            document.getElementById(idPlustext).textContent="-"
        }
        else{
            document.getElementById(idShopList).style.display="none"
            document.getElementById(idPlustext).textContent="+"
            document.getElementById(idShopCat).style.border=""
            document.getElementById(idShopCat).style.borderRadius=""
        }
    }


    const fetchData = (arrin)=>{

        fetch('https://rezasayyahi.ir/dbtest/updateOperator.php',{

        method:'POST',
        headers:{
            'Accept' : 'applicaion/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            arr: arrin
        })
        }).then((response)=>response.json())
        .then((responseJson)=>{
            // console.log('1: ' , responseJson)
            if (responseJson === 'succesfull') {
                setShowSuccess(true)
            } else if(responseJson === 'fail') {
                setShowFailed(true)
            }
        }).catch((error)=>{
            alert(error)
        })
    }

    const confHandler = (machinOpin)=>{
        var arrSent = []
        var mobj={}
        machinOpin.map((data , mindex)=>{
          mobj = {item : data.item, operator : data.operator, operatorName : data.operatorName , shop : data.shop}
        arrSent.push(mobj)
        })

        fetchData(arrSent);
    }

    return (
        <>
        <div className='title_con'>
            <h4 className='title' >تخصیص مسئولیت مجریان فعالیت</h4>
        </div>
        <div className='ass_con'>
            <div className='ass_h_cat'>
                <div className='ass_h_sname'>
                    <h4 >
                        نام سالن
                    </h4>
                </div>
                <div className='ass_h_name'>
                    <h4>مجری فعالیت</h4>                               
                </div>
                
            </div>

            {uniqueShop.map((data , index)=> {
                let idShopCat = `shopcat${index+1}`;              
                let idShopList = `list${index+1}`;
                let idPlustext = `plusText${index+1}`;
                var i= 0;
                return( 
                    <div className='ass_con'>
                        <div key={index} className='asscat' id={idShopCat}>
                            <div className='ass_shop_name'>
                                <span className='plus' onClick={()=>plusClick(idPlustext, idShopList,idShopCat)}><h1 id={idPlustext}>+</h1></span>
                                <a onClick={()=>plusClick(idPlustext, idShopList,idShopCat)}>{data.Shop_Name}</a>
                            </div>
                            <div className='ass_sel'>
                                <h4>مجری فعالیت :</h4>
                                <div id={data.Shop_Name} className='ass_name' onClick={()=>selHandle('sList',index, data.Shop)}>
                                    {shopOpin[index].operator}
                                </div>
                            </div>
                            <div className="mod_con">
                                <Modal show={showModal} modalClosed={modalClosed} className="Modal">
                                    <div className='modal_sel_header'>
                                        <i onClick={modalClosed} className="close fas fa-window-close"></i>
                                        <div>انتخاب مجری</div>
                                    </div>
                                    <div className='modalTextu'>
                                        {userList.filter((item)=>{
                                        return (item.Access_Work_Center.includes(workCenter) & (item.Access_Shop.includes(modalShop)||item.Access_Shop.includes('all')))
                                        }).map((mdata , mindex)=> {          
                                        return(
                                            <div
                                                key={mindex} 
                                                onClick={()=>modalSelect(machinItem,modalIndex,modalShop,mdata.User_Number, mdata.Full_Name)}
                                                className='modal_select'>
                                                {mdata.Full_Name}
                                            </div>
                                        )})}
                                    </div>
                                </Modal>
                                <Modal show={showSuccess} modalClosed={modalClosed} className="Modal_Success">
                                    <div className='mod_closeCon'>
                                        <i onClick={modalClosed} className="close fas fa-window-close"></i>
                                        <TaskAltOutlinedIcon style={{color:`var(--green)`, fontSize:'2rem'}}/>
                                    </div>
                                        <div className='hintModal_text' style={{border:`1px solid var(--green)`}}>
                                            <h3>تغییرات با موفقیت در سرور ذخیره گردید</h3>
                                        </div>
                                </Modal> 
                                <Modal show={showFailed} modalClosed={modalClosed} className="Modal_Failed">
                                    <div>
                                        <i onClick={modalClosed} className="close fas fa-window-close"></i>
                                        <br/>
                                    </div>
                                    <div className='modalText'>
                                        {<div className='hintModal'>
                                            <h1>❌&nbsp;</h1>
                                            <h3>عملیات ثبت با مشکل مواجه گردید!</h3>
                                        </div>}
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className='ass_shoplist' id={idShopList}>
                            {machinOpin.map((ldata , lindex)=> {                                
                                if (ldata.shop === data.Shop & ldata.workCenter === workCenter) {
                                    i++;
                                    return(                                        
                                    <div key={lindex} className='ass_shopitem'
                                        onClick={()=>itemSelect('mList',ldata.item, lindex ,ldata.Machin ,ldata.shop)}>
                                        <h1 className='m_item'>{(i)+ ' - ' + ldata.machinName}</h1>
                                        <h2 className='st_item'>({ldata.stname} {!(ldata.st =='') && (' اا '+ (ldata.st))})</h2>
                                        <h1 className='op_item'> { ldata.operatorName}</h1>
                                    </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                )                
            })}
        </div>
        <button onClick={()=>confHandler(machinOpin)} className='btn_conf stikbottom'>ثبت تغییرات</button>
    </>
  )
}
