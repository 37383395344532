import React, { useContext, useEffect, useMemo, useState } from 'react';

import { userdata } from '../App';
import { useFetch } from "../hooks/useFetch"

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import './listcat.css'
import Modal from "./Modal";
import { useNavigate } from 'react-router-dom'
import Chart from './Pie_Chart'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import His_year_filter from './His_year_filter';

//-------------------------------------------------------------------

export default function history(props) {

    const [showModal,setShowModal] = useState(false);
    const [modalShop,setModalShop] = useState()
    const [modalIndex,setModalIndex] = useState()
    const [modSelect,setModSelect] = useState('s')
    const [machinItem,setMachinItem] = useState('')
    const [showSuccess,setShowSuccess] = useState(false)
    const [showFailed,setShowFailed] = useState(false)
    const navigate = useNavigate()
    
    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
      setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
      machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
      stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
      boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
      uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
      userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
      setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
      workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
      shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
      isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
      weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
      executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
      pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
      uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
      his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
      historyyear, SetHistoryyear, resultInfo,setResultInfo, uniqueYear, SetUniqueYear,conditionData,setConditionData,
      conditionBackData,setConditionBackData
        } = useContext(userdata)
  

    useEffect(()=>{
        // console.log('hisrory - category = ', categoryView)
        setPageTitle(section + ' / ' + 'سوابق فعالیتها')
        refresh()
        
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
        }
    }, [])

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        navigate('/history')
        setBoxShowState('WorkCenterCategory')
    }

    const [showWait,setShowWait] = useState(false)
    const [dataBack,setDataBack] = useState([])

//--------------------------------------------------------
    const modalClosed=()=>{
        setShowWait(false)
        setShowModal(false)
        setShowSuccess(false)
        setShowFailed(false)
    }
//--------------------------------------------------------

//--------------------------------------------------------
    var backResult = ''
    var backData = ''
    var wOfcurrent = ''
    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
    const pdate = new DateObject({ calendar: persian, locale: persian_fa});

    //--------------------------------------------------------
    async function postJSON(data) {
        try {
          const response = await fetch('https://rezasayyahi.ir/dbtest/conditionCheck.php', {
            method: "POST", // or 'PUT'
            headers: {
              'Accept' : 'applicaion/json',
              "Content-Type": "application/json",
            },
            body:JSON.stringify({
                    arr: data
                })
          });
      
          const result = await response.json();
          backResult = result.result
          backData = result.backData
          if (backResult == 'success') {
            setDataBack(backData)
            setShowWait(false)
          }else{
            setShowWait(false)
          }
      
        } catch (error) {
            console.error("Error:", error);
            alert(error)
        }
      }
//--------------------------------------------------------
    const refresh = ()=>{
        setShowWait(true)
        const mArr = []
        var mobj={}
        mobj = {workCenter:workCenter, week:wOfcurrent}
        mArr.push(mobj)

        postJSON(mArr)
    }
//--------------------------------------------------------



//.......................... Fetch Data Satat .............................

async function postJSONhistory(data) {
    try {
      const response = await fetch('https://rezasayyahi.ir/dbtest/history.php', {
        method: "POST", // or 'PUT'
        headers: {
          'Accept' : 'applicaion/json',
          "Content-Type": "application/json",
        },
        body:JSON.stringify({
          arr: data
        })
      })
  
      const result = await response.json();
    //   console.log('Arr11 = ' , result.result)
      
      if (result.result === 'fail') {

      }else{
        setResponse(result.result)
        setH_Response(result.result)

        var allshop = result.result

        var h_uniqe_shop_machinlist =[...new Map(allMachinList.filter((item)=>{
            return (item.Plant === plant)
          }).map((machin)=>[machin.Shop, machin])).values()]

        var lookup_shop_Name=[]
        var obj_shop = {}
        
        h_uniqe_shop_machinlist.map((data)=>{
        obj_shop = {Shop:data.Shop , Shop_Name:data.Shop_Name}
        lookup_shop_Name.push(obj_shop)
        })

        var obj_shop_Name = {}
        lookup_shop_Name.map((data)=>{
            let shop = data.Shop
            let shopName = data.Shop_Name
            obj_shop_Name[shop] = shopName
        })

        var h_uniqe_shop = [...new Map(allshop.filter((item)=>{
            return (item.Plant === plant )
        }).map((shop)=>[shop.Shop, shop])).values()]

        var obj_unique_shop={}
        var Arr_uniqe_shop=[]

        h_uniqe_shop.map(data=>{
            obj_unique_shop = {Shop: data.Shop , Shop_Name: obj_shop_Name[data.Shop]}
            Arr_uniqe_shop.push(obj_unique_shop)
        })

        SetUniqueShop(Arr_uniqe_shop)


        setShowWait(false)
        setBoxShowState('shop')
        // navigate('/task')
      }
    } catch (error) {
        // console.error("Error1:", error);
        alert(error)
      }
  }

  const itemSelect = (btn_week,btn_year)=>{
    setExecuted(true)
      SetHistoryWeek(btn_week)
      SetHistoryyear(btn_year)

      // console.log('Year = ', btn_week , ' - Week_Of_Year = ', btn_year)

      const mArr = []
      var mobj={}

      mobj = {plant:plant, workCenter:workCenter, year:btn_year, W_of_Y:btn_week }

      mArr.push(mobj)

      postJSONhistory(mArr)
      setShowWait(true)
      setPageTitle( section +' / سوابق فعالیتهای هفته '+btn_week + ' سال ' + btn_year)

  }

  const [ww, setWw] = useState(900)
  const [wh, setWh] = useState(300)
 
  const handleResize = () => {
    // console.log('window.innerWidth = ',window.innerWidth)
    if (window.innerWidth < 468) {
      setWw(600)
      setWh(400)
    }
    else {
      setWw(900)
      setWh(300)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
  })


//--------------------------------------------------------

    return (
        <div className='mainDiv1'> 
            <div>
              <h4>لیست سوابق</h4>
              <br/>
            </div>
            <div className='pieChart_con'>
                {(categoryView === 'history' & boxShowState === 'history') && <Chart type ='line' ww = {ww} wh = {wh} />}
            </div>
            <div className='his_year_filter'>
              <His_year_filter/>
            </div>
            <div className={props.clscon}>
                {uniqueWeekOfYear.map((data , index)=> {
                    return(
                    <div key={index} className={props.clscat}
                        onClick={()=>itemSelect(data.Week_Of_Year , data.Year)}>
                        <div className='his_cat_week'>
                          <span>سوابق فعالیت های  </span> 
                          <span style={{padding:'1rem'}}>هفته <span style={{color:`var(--red)`}}> {data.Week_Of_Year} </span> </span> 
                        </div>
                        <div>
                          <span>سال {data.Year}</span> 
                        </div>
                        <div className='his_cat_sec'>  بخش {section} </div>
                    </div>
                    )
                })}
            </div>
            <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
              <div className="ring">
                <div className="spinner"></div>
              </div>
              <div className='modal_wait_Text'>
                  لطفا منتظر بمانید ...
              </div>
            </Modal>

        </div>
    )
}
