import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { userdata } from '../App';

import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';


import { Typography, Stack } from '@mui/material';
import { axisClasses } from '@mui/x-charts';
import { useMemo } from 'react';



const valueFormatter = (value) => `${value}`;

const pieData = [
  { id: 'id_A', value: 35, label: 'تایید شده' },
  { id: 'id_B', value: 25, label: 'دارای مغایرت' },
  { id: 'id_C', value: 40, label: 'دارای توضیحات' },
];


const aData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const dData = [2400, 1398, 800, 308, 800, 1800, 1300];
const cData = [400, 398, 800, 908, 800, 300, 300];


const barData = [
  { data: aData, label: 'کل', id: 'pvId' },
  { data: dData, label: 'دارای مغایرت', id: 'uvId' },
  { data: cData, label: 'دارای توضیحات', id: 'cvId' },
];

const xLabels = ['هفته 34', 'هفته 35','هفته 36','هفته 37','هفته 38','هفته 39','هفته 40',];

export default function Chart(props) {

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
      setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
      machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
      stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
      boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
      uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
      userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
      setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
      workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
      shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
      isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
      weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
      executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
      pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
      uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
      his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
      historyyear, SetHistoryyear, resultInfo,setResultInfo, uniqueYear, SetUniqueYear,conditionData,setConditionData,
      conditionBackData,setConditionBackData,selectYear , setSelectYear
    } = useContext(userdata)

var dataset;

// console.log('props.type = ', props.type)
// const [dataset, setDataset] = useState([])

//--------------------------------------------------------
// console.log('Year1 = ', historyyear , ' - Week_Of_Year1 = ', historyWeek)
// useMemo(()=> 
//   filter_BackData(historyyear, historyWeek)
// ,[historyWeek])

useEffect(() => {
  // filter_BackData(historyyear, historyWeek)
  // console.log('dataset = ', dataset)
})


var filteredWeek = conditionBackData.filter((item)=>
{
  return(item.Year == historyyear & item.Week_Of_Year == historyWeek )
})
// console.log('filteredWeek = ', filteredWeek)


const shop_his_info = []
var obj_shop_his_info = {}

uniqueShop.map((shop_His)=>{

  var allTask = filteredWeek.filter((shopItem)=>{
    return(shopItem.Shop === shop_His.Shop)
  })
  var commentCount = allTask.filter((shopItem)=>{
    return(shopItem.Comment != '')
  })
  var notokCount = allTask.filter((shopItem)=>{
    return(shopItem.Result === 'notok')
  })

  
  obj_shop_his_info = {all:allTask.length, comment:commentCount.length, notok:notokCount.length, shopName:shop_His.Shop_Name}
  
  shop_his_info.push(obj_shop_his_info)
})
dataset = shop_his_info



function filter_BackData(historyyear, historyWeek){

  // console.log('conditionBackData = ', conditionBackData)
  // console.log('uniqueShop = ', uniqueShop)
  // console.log('Year2 = ', historyyear , ' - Week_Of_Year2 = ', historyWeek)

  var filteredWeek = conditionBackData.filter((item)=>
  {
    return(item.Year == historyyear & item.Week_Of_Year == historyWeek )
  })
  // console.log('filteredWeek = ', filteredWeek)

  
  const shop_his_info = []
  var obj_shop_his_info = {}
  
  uniqueShop.map((shop_His)=>{

    var allTask = filteredWeek.filter((shopItem)=>{
      return(shopItem.Shop === shop_His.Shop)
    })
    var commentCount = allTask.filter((shopItem)=>{
      return(shopItem.Comment != '')
    })
    var notokCount = allTask.filter((shopItem)=>{
      return(shopItem.Result === 'notok')
    })

    
    obj_shop_his_info = {all:allTask.length, comment:commentCount.length, notok:notokCount.length, shopName:shop_His.Shop_Name}
    
    shop_his_info.push(obj_shop_his_info)
  })
  dataset = shop_his_info
  // setDataset(shop_his_info)
}

// console.log('dataset = ',dataset)


//--------------------------------------------------------



const [identifier, setIdentifier] = React.useState(null);
const [id, setId] = React.useState(undefined);

const countAll = []
const countComment = []
const countNok = []
const xLabel = []

const chartSetting = {
  yAxis: [
    {
      label: 'تعداد فعالیت',
      fontSize: 100,
    },
  ],
  width: props.ww,
  height: props.wh,
  fontSize: 100,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
      fontSize: 100,
    },
  },
};

//****************************************************************



for (let index = 0; index < resultInfo.length; index++) {
  countAll.push(resultInfo[index].taskCount);
  countComment.push(resultInfo[index].commentCounter)
  countNok.push(resultInfo[index].errorCount)
  xLabel.push(resultInfo[index].week)
}
// console.log('countAll = ',countAll , 'countComment = ' , countComment ,'countNok = ',countNok , 'xLabel = ',xLabel)
const handleClick = (event, itemIdentifier, item) => {
  // setId(item.id);
  // setIdentifier(itemIdentifier);
};

if (props.type === 'pie') {
  return (
    <div className='pie_chart'>
      <Stack>
        <PieChart
          series={[
            {
              data: pieData,
            },
          ]}
          width={400}
          height={200}
          margin={{ right: 200 }}
        />
      </Stack>
    </div>
    )
  
} else if (props.type === 'line'){
  return (
    <LineChart
      width={props.ww}
      height={props.wh}
      series={[
        // { data: countAll, label: 'all_task_AAAAAAAAAA' },
        { data: countAll, label: 'کل فعالیتها     ' },
        { data: countNok, label: 'دارای مغایرت   ' },
        { data: countComment, label: 'دارای توضیحات' },
      ]}
      xAxis={[{ data: xLabel,label: 'هفته' }]}
    />
  );
  
}else if(props.type === 'bar'){
  
  return (
    <BarChart
      width= {900}
      height={700}
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'shopName' }]}
      series={[
        { dataKey: 'all', label: 'کل فعالیتها      ', valueFormatter },
        { dataKey: 'notok', label: 'دارای مغایرت      ', valueFormatter },
        { dataKey: 'comment', label: 'دارای توضیحات      ', valueFormatter }
      ]}

      {...chartSetting}
    />
  );

}
}
