import { BrowserRouter, Router, Routes, Route, NavLink, useLoaderData, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFetch } from "../../hooks/useFetch" 

import { userdata } from '../../App';


import DatePicker, { DateObject } from "react-multi-date-picker"

import { Calendar } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import './main.css'

import Modal from '../../components/Modal';
import Footer from '../../components/Footer';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { Prev } from 'react-bootstrap/esm/PageItem';


export default function Main() {


  window.scrollTo({ top: 0, behavior: "smooth" })

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
    pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
    uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,planBtn,setPlanBtn
  } = useContext(userdata)

  const [showWait,setShowWait] = useState(false)
  const [showSmile,setShowSmile] = useState(false)

  const [workLevel,setWorkLevel] = useState([])

  const date = new DateObject({ calendar: persian, locale: persian_fa });
  const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

  var levelText;
  var work_section_Array = [];

  function commaCount(text,db_machinlist) {
    const workLevelArray = [];
    const workCenter_section_Array = [];
    var obj_workCenter_section_Array = {}
    const myArray = text.split(",");
    let comma = 0

    for (let i = 0; i < text.length; i++) {
      if (text[i] === ',') {
        comma++
      }
    }
    for (let index = 0; index <= comma; index++) {
      workLevelArray.push(myArray[index])
    }
    
    const uniqueSection = [...new Map(db_machinlist.map((machin)=>[machin.Work_Center, machin])).values()]
    

    for (let index = 0; index < workLevelArray.length; index++) {
      uniqueSection.map((item,i)=>{
      if (item.Work_Center === workLevelArray[index]) {
        obj_workCenter_section_Array = {workCenter:item.Work_Center , section:item.Section}
        workCenter_section_Array.push(obj_workCenter_section_Array)
      }
      })
    }
    work_section_Array = workCenter_section_Array
    setWorkLevel(workCenter_section_Array)
    
  }

  const [url1, setUrl1] = useState('https://rezasayyahi.ir/dbtest/pmlist.php');
  const {data : pmlist, isLoading:pmLoading, error:pmError} = useFetch(url1);

  
  const [url, setUrl] = useState('https://rezasayyahi.ir/dbtest/machinlist.php');
  const {data : db_machinlist, isLoading:mLoading, error:mError} = useFetch(url);

  if (db_machinlist) {
    // console.log('db_machinlist = ',db_machinlist)
  }


  const modalClosed=()=>{
    setPmShowWait(false)
    setShowSmile(false)   
  }

  const msg_conn_Error = <h4>عدم برقراری ارتباط با سرور مرکزی</h4>
  const msg_conn_loading = <h4>در حال بارگذاری اطلاعات ...</h4>

  const btnTask = <span>لیست فعالیت ها</span>
  const btnSettings = <span>انتخاب مجریان فعالیتها</span>
  const btnPlan = <span> تنظیمات سطوح Planner </span>
  const btnUsers = <span>لیست کاربران</span>
  const btnCondition = <span>گزارش وضعیت اجرای فعالیتها</span>
  const btnHistory = <span>سوابق فعالیتها</span>
  var ArrBtnTxt = []
  var ArrBtnKey = []
  
  if (userPosition === 'man' || userPosition === 'planner') {
    ArrBtnTxt = [btnTask, btnSettings, btnPlan, btnUsers, btnCondition, btnHistory]
    ArrBtnKey = ['task', 'settings','plan', 'users', 'summery', 'history']
  } else if(userPosition === 'head') {
    ArrBtnTxt = [btnTask, btnSettings, btnUsers, btnCondition, btnHistory]
    ArrBtnKey = ['task', 'settings', 'users', 'summery', 'history']
  }else if(userPosition === 'eng'){
    ArrBtnTxt = [btnTask, btnCondition, btnHistory]
    ArrBtnKey = ['task', 'summery', 'history']
  }else if(userPosition === 'tech'){
    ArrBtnTxt = [btnTask]
    ArrBtnKey = ['task']
  }
  const btnMain = []
  var obj_btntxt = {}


  for (let index = 0; index < ArrBtnTxt.length; index++) {
    let constKey = ArrBtnKey[index]
    let constValue = ArrBtnTxt[index]
    obj_btntxt = {con:constKey, value:constValue}
    btnMain.push(obj_btntxt)
  }

  var connError
  var connLoading
  var noErr_noLoading 
  

  const navigate = useNavigate()  

  const onBackButtonEvent = (e) => {
      // e.preventDefault();
      setBoxShowState('');
      navigate('/')
      setTaskHint('')
  }
  // console.log('work = ' , workCenter)

  useEffect(()=>{
    setPageTitle('صفحه اصلی')
    setTaskHint('')
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    }
    
  }, []);
  useEffect(()=>{
    // setShowSmile(true)
    setShowWait(true)
    if (db_machinlist) {
      fetchData(db_machinlist)
    }

  }, [db_machinlist]);



  
//----------------------------------------------------------------------------------
  const fetchData = (db_machinlist)=>{
    const alluser = 'alluser'
    const pass = '0000'

    fetch('https://rezasayyahi.ir/dbtest/user_login.php',{

        method:'POST',
        headers:{
            'Accept' : 'applicaion/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            usernumber: alluser,
            password: pass
        })
    }).then((response)=>response.json())
        .then((responseJson)=>{
            setUserList(responseJson)
            setShowWait(false)
            responseJson.map((data , index)=> {
              if (data.User_Number == userNumber) {
                setWorkCenter(data.Work_Center);
                setUserShopLevel(data.Access_Shop);
                setUserWorkLevel(data.Access_Work_Center)
                levelText = data.Access_Work_Center

                console.log('levelText = ',levelText)
                commaCount(levelText,db_machinlist)

                setUserPosition(data.Position)
              }
            })
        }).catch((error)=>{ 
            alert(error)
        })
  }
//--------------------------------------------------------------------



  if (pmError || mError) {
    connError = true
  }else{
    connError = false
  }
  if ((pmLoading || mLoading)) {
    connLoading = true
  } else {
    connLoading = false
  }
  noErr_noLoading = !connError & !connLoading
//--------------------------------------------------------------------

const btnPlanList = <span>لیست پلن ها</span>
const btnMachinList = <span> لیست ماشین ها</span>
const btnPmList = <span> لیست فعالیتها </span>
var ArrPlanBtnTxt = []
var ArrPlanBtnKey = []
const planBtnArray = [];
var obj_planBtn = {}

const createPlanBtn =()=>{
  ArrPlanBtnTxt = [btnPlanList, btnMachinList, btnPmList]
  ArrPlanBtnKey = ['planList', 'machinList', 'pmList']
  
  for (let index = 0; index < ArrPlanBtnTxt.length; index++) {
    obj_planBtn = {btn : ArrPlanBtnKey[index] , text : ArrPlanBtnTxt[index] }
    planBtnArray.push(obj_planBtn)
  }
  setPlanBtn(planBtnArray)
}


//--------------------------------------------------------------------


  const handler= (btnSent)=>{
      var setORtask;
      if (btnSent==='settings') {
        setCategoryView('settings')
        setORtask=true 
        fetchData(db_machinlist);
        setUserWorkLevel(workCenter)
        
      } else if(btnSent==='task') {
        setCategoryView('task')
        fetchData(db_machinlist)
        setORtask=true 

      }else if(btnSent === 'summery'){
        setCategoryView('summery')
        setORtask=true
        fetchData(db_machinlist)
        setUserWorkLevel(workCenter)

      }else if(btnSent === 'users'){
        setCategoryView('users')

      }else if(btnSent === 'history'){
        setCategoryView('history')

      }else if(btnSent === 'plan'){
        createPlanBtn()
        setCategoryView('plan')
      }

      setPmlist(pmlist)

      if (db_machinlist) {
  
        if(userShopLevel === 'owner'){
          SetUniquePlant([...new Map(db_machinlist.map((machin)=>[machin.Plant, machin])).values()]);
          SetUniqueWorkCenter([...new Map(db_machinlist.map((machin)=>[machin.Work_Center, machin])).values()]);
          setBoxShowState('plant')
          setPlantView(1)

          var OperatorList =[...new Map(db_machinlist.filter((item)=>{
            return (workCenter == item.Work_Center) 
          }).map((machin)=>[machin.Operator_Name, machin])).values()];
        
        }else{
          if (setORtask) {
            var punique=[...new Map(db_machinlist.filter((item)=>{
              return (workCenter === item.Work_Center) 
            }).map((machin)=>[machin.Plant, machin])).values()];
              
            SetUniqueWorkCenter([...new Map(db_machinlist.filter((item)=>{
              return (workCenter === item.Work_Center)
            }).map((machin)=>[machin.Work_Center, machin])).values()]);

            SetUniqueOperatorList([...new Map(db_machinlist.filter((item)=>{
              return (workCenter === item.Work_Center)
            }).map((machin)=>[machin.Operator_Name, machin])).values()]);

          } else {
            var punique=[...new Map(db_machinlist.filter((item)=>{
              return (userWorkLevel.includes(item.Work_Center)) 
            }).map((machin)=>[machin.Plant, machin])).values()];
              
            SetUniqueWorkCenter([...new Map(db_machinlist.filter((item)=>{
              return (userWorkLevel.includes(item.Work_Center)) 
            }).map((machin)=>[machin.Work_Center, machin])).values()]);
          }
          console.log('punique.length = ',punique.length)
          if(punique.length>1){
            SetUniquePlant(punique)
            setBoxShowState('plant')
            setPlantView(1)
          }else if(punique.length==1){
            setPlantView(0)
            setWorkView(1)
            setBoxShowState('WorkCenterCategory')      
            setPlantName(punique[0].Plant_Name);
            setPlant(punique[0].Plant);
            setPageTitle(punique[0].Plant_Name +' / ');
          }else if(punique.length==0) {
            setBoxShowState('plant')
            SetUniquePlant(punique)
            setTaskHint(()=>{
              return(
                <div>
                  <div>فعالیتی برای گروه کاری شما تعریف نشده است1</div>
                  <div>
                    <button onClick={onBackButtonEvent} className='btn_cur'> بازگشت </button>
                  </div>
                </div>
              )
            })          
            SetUniqueShop()
          }        
        }
  
        setAllMachinList(db_machinlist);
        // setBoxShowState('plant')
      } else {
        setBoxShowState('plant')
        setTaskHint(()=>{
          return(
            <div>
              <div>فعالیتی برای گروه کاری شما تعریف نشده است</div>
              <div>
                <button onClick={onBackButtonEvent} className='btn_cur'> بازگشت </button>
              </div>
            </div>
          )
        })
        SetUniqueShop()
      }
  }


var temp = '';

const [values,setValues] = useState([])
const [h_startDate, setH_startDate] = useState()
const [h_endDate, setH_endDate] = useState()

const dateSelect = (value)=>{

  if (value[0] & value[1]) {
    setH_startDate(p2e(value[0].format('')))
    setH_endDate(p2e(value[1].format('')))
  }
}


  return (
    <>
        <div className='top-gap'>
          <h1>   </h1>
        </div>
        {/* <div className='date_time'>
            <h1>{date.format("dddd")} {date.format()}</h1>
        </div> */}
        <div className='mainPage'>
            <div>
              <h3 style={{margin:'1rem'}}>صفحه اصلی</h3>
              {/* {
                <h3>تاریخ انتخاب شده : {h_startDate}  لغایت {h_endDate} </h3>
              } */}
              <h4>{taskHint} </h4>
              <div>
                {connError && msg_conn_Error}
                {connLoading && msg_conn_loading}
              </div>
              
              {noErr_noLoading && (
                <>
                  <div className='mainBtncon'>
                    {btnMain.map((data , index)=> {
                      let path = '/'+data.con
                      return(
                        <NavLink to = {path} key={index} className='mainBtncat' onClick={()=>handler(data.con)}>
                          {data.value}
                        </NavLink>
                      )
                    })}
                  </div>
                  <div className='mainDashboard'>
                    {/* {workLevel.map((item,i)=>{
                      return(
                        <div key={i} className='dashboardItem'>
                          {item.section}
                        </div>
                      )
                    })} */}

                  </div>
                </>
              )}
            </div>
        </div>
        {/* <DatePicker
          plugins={[
            <DatePanel position="left" />
          ]}
          value={values}
          onChange={dateSelect}
          range
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
        /> */}
        <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
              <div className="ring">
                <div className="spinner"></div>
              </div>
              <div className='modal_wait_Text'>
                  لطفا منتظر بمانید ...
              </div>
        </Modal>

        <Modal show={showSmile} modalClosed={modalClosed} className="hintModal">
          <div className='mod_closeCon'>
              <i onClick={modalClosed} className="close fas fa-window-close"></i>
          </div>
          <div className='wellcom_Modal_text' style={{border:`1px solid var(--green)`}}>
              <h4>لطفا فقط با لبخند وارد شوید </h4>
              <EmojiEmotionsOutlinedIcon style={{color:`var(--green)`, fontSize:'2rem', marginRight:'.5rem'}}/> 
          </div>
        </Modal>

        <Footer/>
    </>
  )
}