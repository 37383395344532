import React, { useContext,useEffect, useState } from 'react'
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { userdata } from '../App';

export default function His_year_filter() {
    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
      setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
      machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
      stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
      boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
      uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
      userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
      setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
      workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
      shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
      isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
      weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
      executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
      pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
      uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
      his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
      historyyear, SetHistoryyear, resultInfo,setResultInfo, uniqueYear, SetUniqueYear,conditionData,setConditionData,
      conditionBackData,setConditionBackData,selectYear , setSelectYear
    } = useContext(userdata)

    function toPersianNum( num, dontTrim ) {
      var i = 0,
  
          dontTrim = dontTrim || false,
  
          num = dontTrim ? num.toString() : num.toString().trim(),
          len = num.length,
  
          res = '',
          pos,
  
          persianNumbers = typeof persianNumber == 'undefined' ?
              ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'] :
              persianNumbers;
  
      for (; i < len; i++)
          if (( pos = persianNumbers[num.charAt(i)] ))
              res += pos;
          else
              res += num.charAt(i);
  
      return res;
  }

    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
    const pdate = new DateObject({ calendar: persian, locale: persian_fa});
    

    function weekUpdate(data,backData,type,selectedYear) {

      // console.log('data = ', data)
      // console.log('backData = ', backData)
      // console.log('type = ', type)
      // console.log('selectedYear = ', selectedYear)


      const yearUnique = [...new Map(backData.filter((item)=>{
        return (item.Work_Center === data[0].workCenter)
      }).map((year)=>[year.Year, year])).values()]
      yearUnique.sort((a,b)=> b.Year - a.Year)
    
      // console.log('yearUnique = ',yearUnique)
      SetUniqueYear(yearUnique)
      
      var yearItem;
      if (type == 'init') {
        yearItem = yearUnique[0].Year
      } else if(type == 'select') {
        yearItem = selectedYear
      }
    
      const weekUnique = [...new Map(backData.filter((item)=>{
        return (item.Work_Center === data[0].workCenter & item.Year === yearItem)
      }).map((week)=>[week.Week_Of_Year, week])).values()]
    
      weekUnique.sort((a,b)=> a.Week_Of_Year - b.Week_Of_Year)
      
      // console.log('weekUnique = ',weekUnique)
      
      const ArrTaskCount = []
      for (let index = 0; index < weekUnique.length; index++) {
        const week = weekUnique[index].Week_Of_Year;
        // console.log('week =',week)
        var objTaskNumber = {}
        var taskNumber = backData.filter((item)=>{
          return (item.Week_Of_Year === week & item.Work_Center === workCenter)
        }).length
        var errorCounter = backData.filter((item)=>{
          return (item.Week_Of_Year === week & item.Work_Center === workCenter &
            item.Result === 'notok')
        }).length
        var commentCounter = backData.filter((item)=>{
          return (item.Week_Of_Year === week & item.Work_Center === workCenter &
            item.Comment != '')
        }).length
    
        objTaskNumber = {week:week , taskCount:taskNumber, errorCount:errorCounter,
           commentCounter:commentCounter}
        ArrTaskCount.push(objTaskNumber);
      }
      // console.log('ArrTaskCount = ' , ArrTaskCount)
      setResultInfo(ArrTaskCount)
      SetUniqueWeekOfYear(weekUnique)
    }

    useEffect(()=>{
      weekUpdate(conditionData,conditionBackData,'select',selectYear)
    }, [selectYear])

    useEffect(()=>{
      setSelectYear(p2e(selectYear))
    }, [])


  return (
    <div className='his_main_div'>
      <div className='his_filter_con'>
        <div className='his_filter_txt'>فیلتر نتایج بر اساس سال</div>
        <select className='year_filter_sel' value={selectYear} onChange={e => setSelectYear(e.target.value)}>
          {uniqueYear.map((item , index)=>
            <option value={item.Year} key={index}>{toPersianNum(item.Year)}</option>
          )}
        </select>
      </div>
      <div className='his_sel_bottom'></div>
    </div>
  )
}
