import React from 'react'
import Backdrop from '../UI/backdrop/Backdrop'
import './listcat.css'

export default function Modal(props) { 
  return (
    <React.Fragment>
        <Backdrop show={props.show} modalClosed={props.modalClosed}/>
        <div className={props.className}
            style={{transform:props.show?'translateX(0)':'translateX(-100vw)',opacity:props.show?'1':'0'}}>
            {props.children}
        </div>
    </React.Fragment>
    
  )
}
