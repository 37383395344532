
import { useContext, useEffect, useState } from 'react'
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import './planpage.css'

import PlantList from '../../components/PlantList'
import PlantList_left from '../../components/PlantList_left'
import WorkCenterCategory from '../../components/WorkCenterCategory'
import PlanList from '../../components/PlanList'

import Footer from '../../components/Footer'

import { userdata } from '../../App'
import { Navigate } from 'react-router-dom'
import SideBar from '../../components/SideBar'

export default function Home() {

  window.scrollTo({ top: 0, behavior: "smooth" })

  useEffect(()=>{
    // setBoxShowState('plant')

  }, [])

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
    pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
    uniqueWeekOfYear, SetUniqueWeekOfYear
  } = useContext(userdata)

  const page_navigate = ()=>{
    boxShowState ==='pm' &&
      setBoxShowState('machin');
      setPageTitle(plantName +'/'+ shopName +'/');
      setToppageTitle(pageTitle);

    boxShowState ==='machin' && 
      setBoxShowState('shop');
      setPageTitle(plantName +'/');

    boxShowState ==='shop' && 
      setBoxShowState('plant')
  }
  
  const date = new DateObject({ calendar: persian, locale: persian_fa });

  return (
    <div>
        <div className='top-gap'>
            <h1>  </h1>
        </div>
            <div className='maindiv'>
              <div className='leftSide'>
                
                {boxShowState === 'WorkCenterCategory' && <PlantList_left clscon = 'btncon_side' clscat = 'btncat_side' />}              
              </div>
              <div className='boxContainer'>
                {boxShowState === 'plant' && <PlantList clscon = 'btncon' clscat = 'btncat' />} 
                {boxShowState === 'WorkCenterCategory' && <WorkCenterCategory clscon = 'btncon' clscat = 'btncat' />}
                {boxShowState === 'plan' && <PlanList clscon = 'btncon' clscat = 'btncat' />}
              </div>
              
          </div>
          <Footer />

    </div>
  )
}
