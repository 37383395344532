
import { useContext, useEffect, useState } from 'react';
import './loginpage.css';
import { userdata } from '../../App'

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import { useFetch } from '../../hooks/useFetch';

import Axios from "axios";
import { object } from 'yup';


const LoginPage =(props)=>{

    const [password , setPassword] = useState("")
    const [hint , setHint] = useState(' ')    

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear, resultInfo,setResultInfo, uniqueYear, SetUniqueYear,conditionData,setConditionData,
        conditionBackData,setConditionBackData,selectYear , setSelectYear,userWorkLevel_text, setUserWorkLevel_text
      } = useContext(userdata)
    
    const loginValidate =()=>{
        if(userNumber === ''){
            setHint('شماره پرسنلی را وارد نمایید');
            return false;
        }
        else if(userNumber.length < 8){
            setHint('شماره پرسنلی صحیح نیست!');
            return false;
        }
        else if(password ===''){
            setHint('رمز عبور را وارد نمایید');
            return false;
        }
        else{
            setHint('');
            return true;
        }
    }
    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    const do_login=(event)=>{
        event.preventDefault();
        const validateResult =  loginValidate();
        if(validateResult){
            fetch('https://rezasayyahi.ir/dbtest/user_login.php',{
                method:'POST',
                headers:{
                    'Accept' : 'applicaion/json',
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    usernumber:userNumber,
                    password:password
                })
            }).then((response)=>response.json())
                .then((responseJson)=>{
                    if(!(responseJson.Full_Name === 'شماره پرسنلی یا کلمه عبور صحیح نمی باشد')){
                        setFullName(responseJson.Full_Name);
                        setWorkCenter(responseJson.Work_Center);
                        setUserShopLevel(responseJson.Access_Shop);
                        setUserWorkLevel(responseJson.Access_Work_Center);
                        setUserWorkLevel_text(responseJson.Access_Work_Center);

                        setUserPosition(responseJson.Position)
                        setIsLoggedin(true);
                        setUserData(responseJson);
                        localStorage.setItem("isLoggedin",'1');
                        localStorage.setItem("userdata",responseJson);
                         
                        localStorage.setItem("fullname",responseJson.Full_Name);
                        localStorage.setItem("usernumber",userNumber);
                        localStorage.setItem("workcenter",responseJson.Work_Center);
                        localStorage.setItem("usershoplevel",responseJson.Access_Shop);
                        localStorage.setItem("userworklevel",responseJson.Access_Work_Center);
                    }
                    else{
                        setHint('شماره پرسنلی یا کلمه عبور صحیح نمی باشد')
                    }
                }).catch((error)=>{
                    alert(error)
                })
        }
    }

    return(
        <div>
            <div className='top-gap'>
                <h1>  </h1>
            </div>
            
            <div className='login_box'>
                <div id='wrapper'>
                    <div id='login_form'>
                        <h1>ورود كاربران</h1>
                        <h3 id='login_label'></h3>
                        <div className="Login">
                            <Form onSubmit={do_login}>
                                <div className='fa-shield-check' >
                                <Form.Group size="s" controlId="usernumber">
                                    <Form.Control
                                        autoFocus
                                        type="text"
                                        placeholder= "شماره پرسنلی"
                                        value={userNumber}
                                        onChange={(e) => setUserNumber(p2e(e.target.value))}
                                    />
                                </Form.Group>
                                </div>

                                <Form.Group size="lg" controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder= "کلمه عبور"
                                        value={password}
                                        onChange={(e) => setPassword(p2e(e.target.value))}
                                    />
                                </Form.Group>
                                <Button block="true" size="lg" type="submit" className='btn_cur'>
                                {/* <Button block="true" size="lg" type="submit" className='btn_cur' disabled={!validateForm()}> */}
                                    ورود
                                </Button>
                            </Form>
                        </div>
                        {/* <h2 className= "validation_hint">{(errors.usernumber || errors.userpassword)?.message}</h2> */}

                        <h4 className= "validation_hint"> {hint}</h4> 
                        <h2> </h2>

                    </div>

                </div>
             </div>
        </div>
    )
}
export default LoginPage;