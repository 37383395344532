import { useContext, useEffect, useState } from 'react'
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import './users.css'


import { userdata } from '../../App'
import { Navigate, useNavigate } from 'react-router-dom'
import UserList from '../../components/UserList'

import PlantList from '../../components/PlantList'
import WorkCenterCategory from '../../components/WorkCenterCategory'
import Footer from '../../components/Footer'

export default function Users(props) {

  window.scrollTo({ top: 0, behavior: "smooth" })
  
const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
  setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
  machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
  stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
  boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
  uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
  userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
  setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
  workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
  shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
  isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
  weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
  executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
  pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView
  } = useContext(userdata)

  const navigate = useNavigate()

  const onBackButtonEvent = (e) => {
    if (props.clscon === 'btncon') {
      e.preventDefault();
      setBoxShowState('WorkCenterCategory')
      setPageTitle(plantName +' / ') 
    }
  }


  useEffect(()=>{
    setPageTitle('کاربران')
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent); 
      }
    
  }, [])

  
  const date = new DateObject({ calendar: persian, locale: persian_fa });

  return (
    <div>
        <div className='top-gap'>
            <h1>  </h1>
        </div>

        {/* <div className='toptop'>
          <div className='top-idicator'>
            <div className='path'>
              <h3> </h3>{!(boxShowState ==='plant') && <h3>{pageTitle}</h3>}
            </div>
            <div className='date_time'>
              <h3>{date.format("dddd")} {date.format()}</h3>
            </div>
          </div> 

        </div> */}
            <div className='maindiv'>
              <div className='leftSide'>
                  <h6> وبسایت در مراحل طراحی و تکامل می باشد </h6>
                  <h4> صبور باشید ... 😉✌ </h4>
              </div>
              <div className='boxContainer'>
              {boxShowState === 'plant' && <PlantList clscon = 'btncon' clscat = 'btncat' />} 
              {boxShowState === 'WorkCenterCategory' && <WorkCenterCategory clscon = 'btncon' clscat = 'btncat' />}
              {boxShowState === 'users' && <UserList />}
              </div>
          </div>
          <Footer/>

    </div>
  )
}
