import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import Modal from './Modal';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
//-----------------------------------------------------------------

export default function WorkCenterCategory(props) {

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
    pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
    uniqueWeekOfYear, SetUniqueWeekOfYear
    } = useContext(userdata)

    const navigate = useNavigate()

    const onBackButtonEvent = (e) => {
      if (props.clscon === 'btncon') {
        e.preventDefault();
        if (plantView==1){
          setBoxShowState('plant')
          setPageTitle(plantName +' / ') 
        }else if (plantView==0){

            setBoxShowState('');
            navigate('/');
          
        }
      }
    }

  useEffect(()=>{
    // console.log('work - category = ', categoryView)
    // setPageTitle(plantName +' / ');
    // if (props.clscon === 'btncon') {
    //   window.history.pushState(null, null, window.location.pathname);
    //   window.addEventListener('popstate', onBackButtonEvent);
    //   return () => {
    //     window.removeEventListener('popstate', onBackButtonEvent); 
    //   }
    // }
    }, [])

//----------------------------------------------------------------------------------
const [showWait,setShowWait] = useState(false)
const [showModal,setShowModal] = useState(false)
const [showNoHistory,setShowNoHistory] = useState(false)

const modalClosed=()=>{
  setShowWait(false)
  setShowModal(false)
  setShowNoHistory(false)

}


var backResult = ''
var backData = ''
var count = 0
const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
//----------------------------------------------------------------------------------
  async function postJSON(data) {
    try {
      const response = await fetch('https://rezasayyahi.ir/dbtest/conditionCheck.php', {
        method: "POST", // or 'PUT'
        headers: {
          'Accept' : 'applicaion/json',
          "Content-Type": "application/json",
        },
        body:JSON.stringify({
                arr: data
            })
      });
      const result = await response.json();
      backResult = result.result
      backData = result.backData

      if (backResult == 'success') {

        const weekUnique = [...new Map(backData.filter((item)=>{
          return (item.Work_Center === data[0].workCenter)
        }).map((week)=>[week.Week_Of_Year, week])).values()]

        weekUnique.sort((a,b)=> a.Week_Of_Year - b.Week_Of_Year)
        SetUniqueWeekOfYear(weekUnique)
        setBoxShowState('history')
        setShowWait(false)
      }else{
        // console.log('Fail' , backData )
        setShowNoHistory(true)
      }
  
    } catch (error) {
        console.error("Error:", error);
        alert(error)
    }
  }
      
//-------------------------------------------------------------

  const fetchData = ()=>{
    const alluser = 'alluser'
    const pass = '0000'

    fetch('https://rezasayyahi.ir/dbtest/user_login.php',{

        method:'POST',
        headers:{
            'Accept' : 'applicaion/json',
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            usernumber: alluser,
            password: pass
        })
    }).then((response)=>response.json())
        .then((responseJson)=>{
            setUserList(responseJson);
        }).catch((error)=>{ 
            alert(error)
        })
  }
//--------------------------------------------------------------------

  const itemSelect = (btn_workCenter , btn_Section)=>{
    fetchData()
    if (categoryView === 'settings' || categoryView === 'summery') {

      const sunique=[...new Map(allMachinList.filter((item)=>{
        return (item.Plant === plant & item.Work_Center === btn_workCenter)
      }).map((machin)=>[machin.Shop, machin])).values()];
      SetUniqueShop(sunique);

      const Arr =  []
      var obj={}
      sunique.map((data , index)=>{
        obj = { id: index , shop: data.Shop , operator:'-'}
        Arr.push(obj)
      })
      setShopOpin(Arr)
      
      var munique=[...allMachinList]

      const mArr =  []
      var mobj={}
      allMachinList.map((mdata , mindex)=>{
        // console.log('work :' , mdata.Work_Center)
        mobj = {item:mdata.Item, plant:mdata.Plant ,shop: mdata.Shop ,machin: mdata.Machin,
                machinName:mdata.Machin_Name,stname:mdata.Station_Name,st:mdata.Station,
                workCenter:mdata.Work_Center, operator:mdata.Operator, operatorName:mdata.Operator_Name}
        mArr.push(mobj)
      })
      // console.log('mArr: ' ,mArr)
      setMachinOpin(mArr)
      setWorkCenter(btn_workCenter)
      if (categoryView == 'settings') {
        setBoxShowState('userassign')
      } else if(categoryView == 'summery') {
        setBoxShowState('summery')
      }
        
    } else if (categoryView === 'task') { // taskView is true
      if ((userShopLevel==='head') || (userShopLevel==='owner')|| (userShopLevel==='all')|| (userShopLevel==='eng') ) {
        const sunique=[...new Map(allMachinList.filter((item)=>{
          return (item.Plant === plant && item.Work_Center === btn_workCenter)
        }).map((machin)=>[machin.Shop, machin])).values()];
        SetUniqueShop(sunique);
      } else {
        const sunique=[...new Map(allMachinList.filter((item)=>{
          return (item.Plant === plant && item.Work_Center === btn_workCenter && userShopLevel.includes(item.Shop))
        }).map((machin)=>[machin.Shop, machin])).values()];
        SetUniqueShop(sunique);
      }
      setWorkCenter(btn_workCenter);
      setSection(btn_Section)
      setBoxShowState('shop')

    }else if (categoryView === 'users'){
      setWorkCenter(btn_workCenter)
      setBoxShowState('users')

    }else if (categoryView === 'history'){
      const sunique=[...new Map(allMachinList.filter((item)=>{
        return (item.Plant === plant && item.Work_Center === btn_workCenter)
      }).map((machin)=>[machin.Shop, machin])).values()]
      SetUniqueShop(sunique)
      setSection(btn_Section)
      setPageTitle(btn_Section)
      setWorkCenter(btn_workCenter)

      const mArr = [] 
      var mobj={}
      mobj = {workCenter:btn_workCenter, week:'all'}
      mArr.push(mobj)
      setShowWait(true)
      postJSON(mArr)

      // if (props.clscon === 'btncon_side') {
      //   navigate('/history')
      // }


    }
    setSection(btn_Section)
    setPageTitle(btn_Section);
    SetUniqueOperatorList([...new Map(allMachinList.filter((item)=>{
      return (item.Work_Center == btn_workCenter)
    }).map((machin)=>[machin.Operator_Name, machin])).values()]);

  }

  return (
    <div>
      <h5>لیست مراکز کاری</h5>
      <div className={props.clscon}>
        {uniqueWorkCenter.map((data , index)=> {
            return(
              <div key={index} className={props.clscat}
                  onClick={()=>itemSelect(data.Work_Center, data.Section)}>
                  {data.Section}
              </div>
            )
        })}
      </div>
      <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
        <div className="ring">
          <div className="spinner"></div>
        </div>
        <div className='modal_wait_Text'>
            لطفا منتظر بمانید ...
        </div>
      </Modal>
      <Modal show={showNoHistory} modalClosed={modalClosed} className="hintModal">
          <div className='mod_closeCon'>
              <i onClick={modalClosed} className="close fas fa-window-close"></i>
              <ErrorOutlineOutlinedIcon style={{color:`var(--red)`, fontSize:'2rem'}}/>
          </div>
          <div className='hintModal_text' style={{border:`1px solid var(--red)`}}>
            <h3>تاکنون سابقه ای برای گروه کاری انتخاب شده ثبت نگردیده است</h3>
          </div>
      </Modal>
      {/* <Modal show={showNoHistory} modalClosed={modalClosed} className="Modal">
          <div>
              <i onClick={modalClosed} className="close fas fa-window-close"></i>
              <br/>
              <br/>
          </div>
          <div className='modalText'>
              <h1>تاکنون سابقه ای برای گروه کاری انتخاب شده ثبت نگردیده است</h1>
              <br/>
              <br/>
              <div>
                <br/>
                <br/>
                <br/>
              </div>
              <br/>
              <br/>
          </div>
        </Modal> */}
    </div>
  )
}
