
import { useContext, useEffect, useRef, useState } from 'react';
import { userdata } from '../App';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import Modal from './Modal'

import './pmlist.css'

import PeriodSelector from './PeriodSelector'
import Chart from './Pie_Chart'

//------------------------------------------------------------------
export default function PmList(props){

    const navigate = useNavigate()
    const areaRef = useRef(null)

    const [resultComplete , setResultComplete] = useState(false)

    const [showUncomplete,setShowUncomplete] = useState(false)

    const [showSuccess,setShowSuccess] = useState(false)
    const [showWait,setShowWait] = useState(false)
    const [showFailed,setShowFailed] = useState(false)
    const [showModal,setShowModal] = useState(false)

    const [closeModalandReturn,setCloseModalandReturn] = useState(false)

    const modalClosed=()=>{
        setShowUncomplete(false)
        setShowFailed(false)
        setPmShowWait(false)
        setShowModal(false)
        
    }
   

    const onBackButtonEvent = (e) => {
        setPageTitle(section +' / ' +shopName)
        // console.log('props.clscon - pm - back= ', props.clscon )
        if (categoryView === 'summery') {
            // console.log('summeryView = ', summeryView , ' - pmCat = ', pmCat)
            navigate('/Summery')
            setBoxShowState('summery')
        }else{
            // e.preventDefault();
            if (pmCat==1) {
                setBoxShowState('PeriodCategory')
            } else if(pmCat==0) {
                setBoxShowState('machin')
            }
            // navigate('/task' , {replace: true})
        }
    }
    const modalSuccessClosed=()=>{
        setShowSuccess(false)
        onBackButtonEvent()
        // setBoxShowState('machin')
        // setCloseModalandReturn(true)
        setShowFailed(false)
    } 

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear
    } = useContext(userdata)
    
    const [checkedState, setCheckedState] = useState(new Array(finalPmList.length).fill(false))
    // console.log('checkedState',checkedState)
    var checkboxState=[]
    var databaseTask

    useEffect(()=>{
        // console.log('pm - category = ', categoryView)
            //------------------------------------------------------------------------------
            if (executed) {
                // console.log('PMList_effect : finalPM = ', finalPmList, ' -- Response = ',response)
                if (finalPmList.length == response.length) {
                    for (let index = 0; index < response.length; index++) {
                        response.map((item,index1)=>{
                            if (item.Task_Counter == result[index].taskCounter) {
                                result[index].result = item.Result
                                if (item.Result != 'hdy') {                                    
                                    result[index].comment = item.Comment
                                }
                                result[index].operator = item.Operator
                                result[index].operatorName = item.Operator_Name
                            }                            
                        })
                    }
                }
                setShowWait(true)
                setTimeout(()=>setShowWait(false),300)

            }
        //----------------------------------------------------------------------------
        setBoxShowState('pmlist');

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
          window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }, []);


    const date = new DateObject({ calendar: persian, locale: persian_fa });

//.......................... Fetch Data Satat .............................

    async function postJSON(data,type) {
        try {
            const response = await fetch('https://rezasayyahi.ir/dbtest/insertResult.php', {
                method: "POST", // or 'PUT'
                headers: {
                'Accept' : 'applicaion/json',
                "Content-Type": "application/json",
                },
                body:JSON.stringify({
                        arr: data,
                        task: type
                    })
            });
        
            const result = await response.json();
            
            if (result === 'success') {
                // console.log("Success:", result);
                setShowWait(false)
                setShowSuccess(true)
                // setBoxShowState('machin')

            }else{
                // console.log("fail:", result);
                setShowFailed(true)
                setResultComplete(true)

            }
        } catch (error) {
            // console.error("Error:", error);
            alert(error)
            }
    }
  
  //............................ Fetch Data End ..............................
  
    if (closeModalandReturn){
        onBackButtonEvent()

    }

    const submitHandler = ()=>{
        if (executed == true) {
            databaseTask = 'update'
        }else{
            databaseTask = 'insert'
        }
        if (resultComplete) {
            setShowWait(true)
            // setResultComplete(false)
            postJSON(result, databaseTask);
        } else{
            if (!isDisabled & !executed ) {
                setShowUncomplete(true)
            }
        }
    }
    const temporary =()=>{
        setShowWait(true)
        setTimeout(()=>setShowWait(false),300)
        // periodicList()
        // setShowSuccess(false)
    }
    const favEnable = (favid, commLength)=>{
        // console.log('commLength =' , commLength)
        let idfavorite = `fav${favid}`
        if (commLength>0) {
            document.getElementById(idfavorite).style.display="flex"
        } else {
            document.getElementById(idfavorite).style.display="none"
        }
    }
    const commentHandeler = (e)=>{
        // periodicList()
        var textareaid = Number((e.target.id).slice(8)-1)
        // console.log('result-comment = ', result[textareaid].result)
        console.log('result-comment_1 = ', result[textareaid].comment)
        // console.log('result:',result,'textareaid = ',textareaid)
        let favid = Number((e.target.id).slice(8))
        // console.log('isDisabled = ',isDisabled , '-- executed = ', executed, ' -- val = ', e.target.value )

        if (!isDisabled & !executed) {
            result[textareaid].comment = e.target.value        
            let commLength = ((e.target.value).replaceAll(' ', '')).length
            favEnable(favid, commLength)
        }
        // console.log('comm : ',e.target.value , ' - executed : ',executed , ' - result:',result[textareaid].result, 'textareaid: ',textareaid)
        if(executed){
            console.log('execute1-comm= ', executed)
            // result[textareaid].comment = e.target.value
            let R_taskcounter = result[textareaid].taskCounter
            response.map((task,index)=>{
                if ((task.Task_Counter == R_taskcounter) & task.Result == 'hdy') {
                    result[textareaid].comment = e.target.value 
                }
            })
            console.log('result-comment_2 = ', result[textareaid].comment)
            let commLength = ((e.target.value).replaceAll(' ', '')).length
            favEnable(favid, commLength)
        }

        
    }
    
    const plusClick = (idPlustext, idCaption)=>{
        // console.log('plus Clicked')
    }

    const [idHistory , setIdHistory] = useState()

    const btnHistoryClich = (idtextArea)=>{
        setShowModal(true)
        setIdHistory(idtextArea)

    }
    const historySelect = (comment,idHistory)=>{
        setShowModal(false)
        document.getElementById(idHistory).value=comment
        let textareaid = Number((idHistory).slice(8)-1)
        // console.log('id = ', textareaid)

        if (!isDisabled & !executed) {
            result[textareaid].comment = comment       
            // let commLength = ((comment).replaceAll(' ', '')).length
            // favEnable(favid, commLength)
        }
        // console.log('comm : ',e.target.value , ' - exe: ',executed , ' - result:',result[textareaid].result, 'textareaid: ',textareaid)
        if(executed){
            result[textareaid].comment = comment
            let R_taskcounter = result[textareaid].taskCounter
            response.map((task,index)=>{
                // console.log('task.Task_Counter = ',task.Task_Counter,'R_taskcounter = ',R_taskcounter)

                if (task.Task_Counter == R_taskcounter & task.Result == 'hdy') {
                    result[textareaid].comment = comment
                }
            })
        }

    }

//--------------------------------------------------------------------

    const resultCheck = (len)=>{
        result.map((item,index)=>{
            if (item.result ==='ok' || item.result === 'notok' || item.result === 'hdy') {
                len--;
            }else{
                len++;
            }
        })
        if (len == 0) {
            setResultComplete(true)
        }else{
            setResultComplete(false)
        }
        // console.log('result2 : ',result)
    }

    const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

    function toPersianNum( num, dontTrim ) {

        var i = 0,
    
            dontTrim = dontTrim || false,
    
            num = dontTrim ? num.toString() : num.toString().trim(),
            len = num.length,
    
            res = '',
            pos,
    
            persianNumbers = typeof persianNumber == 'undefined' ?
                ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'] :
                persianNumbers;
    
        for (; i < len; i++)
            if (( pos = persianNumbers[num.charAt(i)] ))
                res += pos;
            else
                res += num.charAt(i);
    
        return res;
    }



//------------------------------------------------------------------------------
const checkboxHandle=(position, idbtnOk, idbtnNok,idbtnEdit,idbtnholiday,idCaption,idbtnHis,idtextareaCon)=>{
    setShowWait(true)

    if (result[position].result == 'hdy') {

    } else {
        // console.log('res 2 (pos',position, ') = ', result[position].result)
        document.getElementById(idbtnholiday).style.backgroundColor=`var(--box-bg-ok)`
        document.getElementById(idCaption).style.backgroundColor=`var(--box-bg-ins)`
        document.getElementById(idbtnHis).style.display="none"
        document.getElementById(idbtnEdit).style.display="block"
        document.getElementById(idbtnOk).style.display="none"
        document.getElementById(idbtnNok).style.display="none"
        document.getElementById(idtextareaCon).style.display="none"
        result[position].result = 'hdy'
    }
    setTimeout(()=>setShowWait(false),300)

    resultCheck(result.length)
}

//------------------------------------------------------------------------------
const btnEditHandeler = (idtextArea, idbtnOk, idbtnNok,idCaption,idcheck,idtext,idtextareaCon,idbtnHis,
    idfavorite,idbtnholiday,idbtnEdit,position)=>{
    
    setShowWait(true)
    document.getElementById(idbtnholiday).style.backgroundColor=`var(--white)`

    document.getElementById(idbtnEdit).style.display="none";
    document.getElementById(idtextareaCon).style.display="block";
    document.getElementById(idbtnOk).style.display="block";
    document.getElementById(idbtnNok).style.display="block";
    document.getElementById(idbtnHis).style.display="block";
    document.getElementById(idbtnholiday).style.display="block"

    result[position].result = ''
    document.getElementById(idCaption).style.backgroundColor=`var(--box-bg-ins)`;
    document.getElementById(idtext).style.webkitTextFillColor=`var(--box-txt-ins)`;
    document.getElementById(idCaption).style.Color=`var(--box-txt-ins)`;
    document.getElementById(idCaption).style.border =`1px solid var(--box-border-ins)`;
    document.getElementById(idcheck).style.display="none"
    resultCheck(result.length)
    setTimeout(()=>setShowWait(false),300)
}

var pmState = 1
var tempp=''

const insHandeler=(idtextArea , idbtnOk , idbtnNok, idCaption, idbtnHis,idPlusCon, idfavorite,idtextareaCon,
    idPlusCon1,idbtnholiday,idbtnEdit,position)=>{
    var textaa = document.getElementById(idtextareaCon).style.display
    var indexRes2
    if (textaa === "none" || textaa === "" ) {

        if (executed) { // executed = 1
            response.map((res,index3)=>{
                if (res.Task_Counter == position+1) {
                    indexRes2 = index3
                }
            })
            // console.log('indexRes2 = ',indexRes2)
            if (response[indexRes2].Result === 'hdy'){
                if (result[position].result == '') {
                    document.getElementById(idtextareaCon).style.display="block"
                    document.getElementById(idbtnEdit).style.display="none"
                    document.getElementById(idbtnHis).style.display="block"
                    document.getElementById(idbtnOk).style.display="block"
                    document.getElementById(idbtnNok).style.display="block"
                    document.getElementById(idbtnholiday).style.display="block"
                }else if (result[position].result == 'ok' || result[position].result == 'notok'){
                    document.getElementById(idtextareaCon).style.display="block"
                    document.getElementById(idbtnEdit).style.display="block"
                    document.getElementById(idbtnHis).style.display="none"
                    document.getElementById(idbtnOk).style.display="none"
                    document.getElementById(idbtnNok).style.display="none"
                    document.getElementById(idbtnholiday).style.display="none"
                }

            }else{
                document.getElementById(idbtnOk).style.display="none"
                document.getElementById(idbtnNok).style.display="none"
                document.getElementById(idbtnHis).style.display="none"
                document.getElementById(idfavorite).style.display="none"
                document.getElementById(idPlusCon).style.display="flex"
                document.getElementById(idPlusCon1).style.display="flex"
                document.getElementById(idbtnholiday).style.display="none"
                document.getElementById(idtextareaCon).style.display="block"
                // document.getElementById(idbtnEdit).style.display="block"
            }
            
        } else { // executed = 0
            if (isDisabled) { // user = false
                document.getElementById(idbtnOk).style.display="none"
                document.getElementById(idbtnNok).style.display="none"
                document.getElementById(idbtnHis).style.display="none"
                document.getElementById(idfavorite).style.display="none"
                document.getElementById(idbtnholiday).style.display="none"
                document.getElementById(idtextareaCon).style.display="block"
            }else{ // user = true
                if (result[position].result === '') {
                    document.getElementById(idbtnOk).style.display="block"
                    document.getElementById(idbtnNok).style.display="block"
                    document.getElementById(idbtnHis).style.display="block"
                    document.getElementById(idfavorite).style.display="none"
                    document.getElementById(idbtnholiday).style.display="block"
                    document.getElementById(idtextareaCon).style.display="block"
                    document.getElementById(idbtnEdit).style.display="none"
                    document.getElementById(idbtnholiday).style.backgroundColor=`var(--white)`
                } else if(result[position].result === 'hdy'){
                    
                }else if (result[position].result === 'ok' || result[position].result === 'notok') {
                    document.getElementById(idbtnholiday).style.display="none"
                    document.getElementById(idbtnHis).style.display="none"
                    document.getElementById(idbtnOk).style.display="none"
                    document.getElementById(idbtnNok).style.display="none"
                    document.getElementById(idbtnEdit).style.display="block"
                    document.getElementById(idtextareaCon).style.display="block"
                }                        

                let favid = Number((idtextArea).slice(8))
                var textareaVal = document.getElementById(idtextArea).value
                let commLength = ((textareaVal).replaceAll(' ', '')).length
                favEnable(favid, commLength)
                
            }
        }
    }else{ // texterea close cycle

        if (result[position].result === 'hdy') {
            
        }else if(result[position].result === 'ok' || result[position].result === 'notok'){
            document.getElementById(idtextareaCon).style.display="none"
            document.getElementById(idbtnEdit).style.display="none"
            document.getElementById(idPlusCon).style.display="none"
            document.getElementById(idPlusCon1).style.display="none"

        }else if(result[position].result === ''){
            document.getElementById(idPlusCon).style.display="none"
            document.getElementById(idPlusCon1).style.display="none"
            document.getElementById(idtextareaCon).style.display="none"
            document.getElementById(idfavorite).style.display="none"
            document.getElementById(idbtnholiday).style.display="none"
            document.getElementById(idbtnEdit).style.display="none"
            document.getElementById(idbtnOk).style.display="none" 
            document.getElementById(idbtnNok).style.display="none"
            document.getElementById(idbtnHis).style.display="none"
        }
    }

}

const btnOkHandeler=(idtextArea, idbtnOk, idbtnNok, idCaption, idcheck, idtext, idtextareaCon, idbtnHis,
    idfavorite,idbtnholiday,idbtnEdit,position )=>{
    document.getElementById(idcheck).style.display="inline"
    document.getElementById(idcheck).style.color=`var(--green)`
    document.getElementById(idtextareaCon).style.display="none";
    document.getElementById(idbtnOk).style.display="none";
    document.getElementById(idbtnNok).style.display="none";
    // document.getElementById(idbtnEdit).style.display="bloke";
    
    document.getElementById(idbtnHis).style.display="none"
    document.getElementById(idfavorite).style.display="none"
    document.getElementById(idbtnholiday).style.display="none"
    document.getElementById(idCaption).style.backgroundColor=`var(--box-bg-ok)`;
    document.getElementById(idtext).style.webkitTextFillColor=`var(--box-txt-ok)`;
    document.getElementById(idCaption).style.color=`var(--box-txt-ok)`;
    document.getElementById(idCaption).style.border =`1px solid var(--box-border-ok)`;
    pmState = 1;

    result[position].result = 'ok'
    result[position].date = p2e(date.format())
    result[position].year = p2e(date.format('YYYY'))

    result[position].operator = isOperator
    result[position].operatorName = isOperatorName

    resultCheck(result.length)

}

const btnNokHandeler=(idtextArea, idbtnOk, idbtnNok, idCaption, idcheck, idtext, idtextareaCon,idbtnHis,
    idfavorite,idbtnholiday,idbtnEdit,position)=>{
    document.getElementById(idcheck).style.display="inline"
    document.getElementById(idcheck).style.color=`var(--red)`
    document.getElementById(idtextareaCon).style.display="none";
    document.getElementById(idbtnOk).style.display="none";
    document.getElementById(idbtnNok).style.display="none";
    // document.getElementById(idbtnEdit).style.display="block";
    document.getElementById(idbtnHis).style.display="none"
    document.getElementById(idfavorite).style.display="none"
    document.getElementById(idbtnholiday).style.display="none"
    document.getElementById(idCaption).style.backgroundColor=`var(--box-bg-nok)`;
    document.getElementById(idtext).style.webkitTextFillColor=`var(--box-txt-nok)`
    document.getElementById(idCaption).style.Color=`var(--box-txt-nok)`
    document.getElementById(idCaption).style.border =`1px solid var(--box-border-nok)`
    pmState = 1

    result[position].result = 'notok'
    
    result[position].date = p2e(date.format())
    result[position].year = p2e(date.format('YYYY'))
    result[position].operator = isOperator
    result[position].operatorName = isOperatorName
    resultCheck(result.length)
}

//------------------------------------------------------------------------------
    const periodicList = ()=>{
        
        // console.log('his_filterd_Btn = ', his_filterd_Btn)
        var isOk
        if (executed) {
            console.log('length PM_1 = ' ,finalPmList.length , ' --- ','length RES= ' ,response.length )
            if (finalPmList.length == response.length) {
                isOk = 1
            } else {
                isOk = 0
            }
        }else{
            console.log('length PM_2 = ' ,finalPmList.length)
            checkboxState = checkedState
            isOk = 1
            // console.log('isOk = ',isOk)
        }
    //-------------------------------------------------------------------------
        const arrr = []
       return (
        <>
            {(isOk == 1) && finalPmList.map((filteredpm , index)=>{
                let idCaption = `box${index+1}`; 
                let idtextareaCon = `textareaCon${index+1}`;
                let idtextArea = `textarea${index+1}`;
                let idbtnOk = `btnOk${index+1}`;
                let idbtnNok = `btnNok${index+1}`;
                let idbtnEdit = `btnEdit${index+1}`;
                let idcheck = `idcheck${index+1}`;
                let idtext = `idtext${index+1}`;
                let idPlustext = `plusText${index+1}`;
                let idPlusCon = `plusCon${index+1}`;
                let idPlusCon1 = `plusCon1${index+1}`;
                let idbtnHis = `btnHis${index+1}`;
                let idbtnholiday = `btnHoliday${index+1}`;
                let idfavorite = `fav${index+1}`;

                var classBox
                var classCheck
                var val
                var indexRes

                var H_btn_show
                var E_btn_show

                var H_cheched
                // var E_btn_show
                if (executed) {
                    response.map((res,index2)=>{
                        if (res.Task_Counter === filteredpm.Counter) {
                            if ((categoryView != 'history') & (result[index].result == 'ok' || result[index].result == 'notok')) {
                                val = result[index].comment
                            }
                            if ((categoryView === 'history') & (response[index].Result == 'ok' || response[index].Result == 'notok')) {
                                val = response[index].Comment
                            }
                            
                            indexRes = index2
                        }
                    })
                    
                    if (response[indexRes].Result ==='ok') {
                        classBox = "pmbox_ok"
                        classCheck = 'fa-solid fa-square-check check_isDisable_ok'
                    } else if(response[indexRes].Result ==='notok') {
                        classBox = "pmbox_nok"
                        classCheck = 'fa-solid fa-square-check check_isDisable_nok'
                    }else if(response[indexRes].Result ==='hdy'){
                        classBox = "pmbox"
                        classCheck = 'fa-solid fa-square-check check'
                    }else{
                        classBox = "pmbox"
                        classCheck = 'fa-solid fa-square-check check'
                    }
                    if (response[indexRes].Result === 'hdy') {
                        // result[(indexRes)].result = 'hdy'
                        H_btn_show = true
                        if (!isDisabled) {
                            // console.log('isDisabled : ', isDisabled)
                            E_btn_show = true
                        }else{
                            E_btn_show = false
                        }
                    }else{
                        H_btn_show = false
                        E_btn_show = false
                    }
                }else{ // executed = 0
                    classBox = "pmbox"
                    classCheck = 'fa-solid fa-square-check check'
                }
                return (
                    <div key={index} className={classBox} id={idCaption}
                        onClick={()=>insHandeler(idtextArea , idbtnOk , idbtnNok,idCaption, idbtnHis,idPlusCon, idfavorite,
                        idtextareaCon,idPlusCon1,idbtnholiday,idbtnEdit,index)} >
                        <div className='check_con'> 
                            <div className= {classCheck} id={idcheck}> </div>
                            <p id={idtext}> {filteredpm.Operation_Text}</p>
                        </div>
                        <div className='textareaCon' id={idtextareaCon}>
                            <textarea
                                ref = {areaRef}
                                onClick={(e)=>e.stopPropagation()}
                                className='textArea'
                                id={idtextArea}
                                placeholder="توضیحات/مغایرت :"
                                onChange={commentHandeler}
                                value = {val}
                                maxLength={1000}>
                            </textarea>
                        </div>

                        <div className= {executed ? 'operator':'operator_non'}>
                            <div className='operator_ch'>
                                <h6> اجرا شده توسط :  {executed ? response[indexRes].Operator_Name : '1'} </h6>
                                <h6 style={{color:'var(--red)'}}> تاریخ اجرا :  {executed ? toPersianNum(response[indexRes].Date) : '1'} </h6>
                            </div>
                            

                            <i title='دارای توضیحات' className= {(val)? 
                                ((response[indexRes].Result == 'ok') ? "fa-solid fa-comments blink_green": "fa-solid fa-comments blink_red"):
                                 'operator_non'} >
                            </i>
                        </div>
                        <div id={idfavorite} className= {executed ? 'operator_non':'favorite'}>
                            {/* <h5>افزودن به علاقمندی ها</h5> */}
                        </div>

                        <div className='btn_cont' >
                            <div className='btn_h_con'>
                                <div id={idbtnHis} className='comment_history_con' >
                                    <a
                                        onClick={(e)=>e.stopPropagation() & btnHistoryClich(idtextArea)}>
                                        <h4>انتخاب از سوابق</h4>
                                    </a>
                                </div>
                                <div id={idbtnholiday} className={(H_btn_show == true) ?'holiday_con':'holiday_con_non'} 
                                onClick={(result[index].result == 'hdy') ? ((e)=>e.stopPropagation()):
                                     ((e)=>e.stopPropagation() & checkboxHandle(index, idbtnOk, idbtnNok,idbtnEdit,idbtnholiday,idCaption,idbtnHis,idtextareaCon))}>
                                    <a className='holiday' >
                                        <input 
                                            type="checkbox" id={idbtnholiday} 
                                            name={idbtnholiday} value="holiday" 
                                            className='h_chechbox'
                                            checked={(result[index].result == 'hdy') ? true : false}
                                            // checked={(1===1) ? true : false}
                                            />انجام در روز تعطیل
                                        <br/>
                                    </a>
                                </div>
                            </div>
                            <div className='btn_cont'>
                                <button
                                    // disabled = {isDisabled || executed}
                                    onClick={(e)=>e.stopPropagation() & 
                                        btnOkHandeler(idtextArea, idbtnOk, idbtnNok,idCaption,idcheck,idtext,
                                            idtextareaCon,idbtnHis,idfavorite,idbtnholiday,idbtnEdit,index)}
                                    className='btnOk'
                                    id={idbtnOk}>
                                        عدم مغایرت
                                </button>
                                <button 
                                    // disabled = {isDisabled || executed}
                                    onClick={(e)=>e.stopPropagation() & 
                                        btnNokHandeler(idtextArea, idbtnOk, idbtnNok,idCaption,idcheck,idtext,
                                            idtextareaCon,idbtnHis,idfavorite,idbtnholiday,idbtnEdit,index)}
                                    className= 'btnNok' 
                                    id={idbtnNok}>
                                        دارای مغایرت
                                </button>
                                <button 
                                    // disabled = {isDisabled || executed}
                                    onClick={(e)=>e.stopPropagation() & 
                                        btnEditHandeler(idtextArea, idbtnOk, idbtnNok,idCaption,idcheck,idtext,
                                            idtextareaCon,idbtnHis,idfavorite,idbtnholiday,idbtnEdit,index)}
                                    className= {(E_btn_show == true) ? 'btnEdit' : 'btnEdit_non'}
                                    id={idbtnEdit}>
                                        ویرایش
                                </button>
                            </div>
                        </div>
                        <hr/>
                        <div id={idPlusCon} className='plus_con' >
                            <div id={idPlusCon1} className='plus_con1' onClick={(e)=>e.stopPropagation() & plusClick(idPlustext, idCaption)} >
                                <span
                                    className='plus'>
                                    <h1>+</h1>
                                </span>
                                <a>
                                    <h4> ثبت نظر </h4>
                                </a>
                            </div>
                        </div>
                    </div>
                )})
            }
        </>)       
    }
// console.log('historyComment = ',historyComment)
//--------------------------------------------------------------------------------------------
 
    return( 
        <div className='mainDiv1'>
            <div>
                <h4 style={{right:'0'}}>لیست فعالیتها</h4> 
            </div> 
            {/* <div >
                {categoryView === 'history' && <PeriodSelector type = 'pie'/>}
            </div>            
            <div >
                {categoryView === 'history' && <Chart type = 'pie'/>}
            </div> */}

            {/* <div  > */}
            
            {(categoryView === 'history') && <PeriodSelector/> }
            {periodicList()}
            <button 
                onClick={()=>submitHandler()} 
                className= {(!isDisabled & resultComplete) ? 'btn_confirm pmstikbottom' : 'deActive pmstikbottom'}>
                ثبت نتایج
            </button>
            {/* </div> */}
            <Modal show={showModal} modalClosed={modalClosed} className="ModalHistory">
                <div>
                    <i onClick={modalClosed} className="close fas fa-window-close"></i>
                    <br/>
                    <br/>
                </div>
                    <h5>انتخاب از سوابق ثبت شده</h5>
                <div className='modalTextu_histore'>
                    {historyComment === null ? (<h4>سابقه ی ثبت شده ای وجود ندارد</h4>):
                     historyComment.map((mdata , mindex)=> {
                        if(!(mdata=='')){
                            return(
                                <div key={mindex} className='modal_scroll_box'>
                                    <div
                                        onClick={()=>historySelect(mdata,idHistory)}
                                        className='his_modal_select'>
                                        {mdata}
                                    </div>
                                </div>
                            )}
                        }
                    )}
                </div>
            </Modal>
            <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
                <div className="ring">
                <div className="spinner"></div>
                </div>
                <div className='modal_wait_Text'>
                    لطفا منتظر بمانید ...
                </div>
            </Modal>

            <Modal show={showUncomplete} modalClosed={modalClosed} className="hintModal">
                <div className='mod_closeCon'>
                    <i onClick={modalClosed} className="close fas fa-window-close"></i>
                    <ErrorOutlineOutlinedIcon style={{color:`var(--red)`, fontSize:'2rem'}}/>
                </div>
                <div className='hintModal_text' style={{border:`1px solid var(--red)`}}>
                    <h4 style={{marginBottom:'1.5rem'}}>اجرای کامل تمامی آیتم ها ضروری می باشد!</h4>
                    <h5>لطفا نتیجه تمامی موارد را ثبت نمایید</h5>
                </div>
            </Modal>

            <Modal show={showSuccess} modalClosed={modalSuccessClosed} className="Modal_Success">
                <div className='mod_closeCon'>
                    <i onClick={modalSuccessClosed} className="close fas fa-window-close"></i>
                    <TaskAltOutlinedIcon style={{color:`var(--green)`, fontSize:'2rem'}}/>
                </div>
                    <div className='hintModal_text' style={{border:`1px solid var(--green)`}}>
                        <h3>نتایج با موفقیت در بانک اطلاعاتی ثبت شد</h3>
                        <h4 style={{margin:'2rem 1rem .5rem 1rem'}}>فعالیت مرتبط با این ماشین خاتمه یافت</h4>
                    </div>
            </Modal>
        </div>
    )
}
