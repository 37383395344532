import React from 'react'

import { useContext, useEffect, useState } from 'react'
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import './history.css'


import { userdata } from '../../App'
import { Navigate } from 'react-router-dom'

import History from '../../components/History'

import PlantList from '../../components/PlantList'
import History_left from '../../components/History_left'
import ShopList from '../../components/ShopList'
import ShopList_left from '../../components/ShopList_left'
import MachinList from '../../components/MachinList'
import MachinList_left from '../../components/MachinList_left'
import WorkCenterCategory from '../../components/WorkCenterCategory'
import WorkCenterCategory_left from '../../components/WorkCenterCategory_left'
import PmList from '../../components/PmList'
import Footer from '../../components/Footer'



export default function HistoryPage() {

  window.scrollTo({ top: 0, behavior: "smooth" })
  
  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList
  } = useContext(userdata)
  
  const date = new DateObject({ calendar: persian, locale: persian_fa })

  
  return (
    <>
        <div className='top-gap'>
            <h1>  </h1>
        </div>
            <div className='maindiv'> 
              <div className='leftSide'>
                {boxShowState === 'WorkCenterCategory' && <PlantList clscon = 'btncon_side' clscat = 'btncat_side' />}              
                {boxShowState === 'history' && <WorkCenterCategory_left clscon = 'btncon_side' clscat = 'btncat_side' />}
                {boxShowState === 'shop' && <History_left clscon = 'btncon_side' clscat = 'btncat_side' />}
                {boxShowState === 'machin' && <ShopList_left clscon = 'btncon_side' clscat = 'btncat_side' />}
                {boxShowState === 'pmlist' && <History_left clscon = 'btncon_side' clscat = 'btncat_side' />}
              </div>
              <div className='boxContainer'>
                {boxShowState === 'plant' && <PlantList clscon = 'btncon' clscat = 'btncat' />}
                {boxShowState === 'WorkCenterCategory' && <WorkCenterCategory clscon = 'btncon' clscat = 'btncat' />}
                {boxShowState === 'history' && <History clscon = 'btncon' clscat = 'his_btncat' />}
                {boxShowState === 'shop' && <ShopList clscon = 'btncon' clscat = 'btncat' />}
                {boxShowState === 'machin' && <MachinList clscon = 'btncon' clscat = 'btncat' />}
                {boxShowState === 'pmlist' && <PmList clscon = 'btncon' clscat = 'btncat' />}

              </div>

          </div>
          <Footer/>
    </>
  )
}
