 
import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { useNavigate } from 'react-router-dom'
import { CleaningServices } from '@mui/icons-material';
 
export default function ShopList(props) {

    const onBackButtonEvent = (e) => {
        // console.log('back in shop')
        // console.log('props.clscon - shop - back = ',props.clscon)
        if (props.clscon === 'btncon') {
            e.preventDefault()
        
            if (categoryView === 'history') {
                // console.log('first')
                setBoxShowState('history')
                navigate('/history')
            }else{
                if(workView==1){
                    setBoxShowState('WorkCenterCategory') 
                }
                else if (plantView==1){
                    setBoxShowState('plant') 
                }else{ 
                    navigate('/'); 
                }
            }
        }
    }

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear
    } = useContext(userdata)

    
    const navigate = useNavigate()

    useEffect(()=>{

        // if (categoryView === 'history') {
        //     setPageTitle( section +' / سوابق فعالیتهای هفته '+historyWeek + ' سال ' + historyyear)
        // }else{
        //     setPageTitle( section )
        // }

        // window.history.pushState(null, null, window.location.pathname);
        // window.addEventListener('popstate', onBackButtonEvent);
        // return () => {
        // window.removeEventListener('popstate', onBackButtonEvent);  
        // }
    }, [])

    const [showWait,setShowWait] = useState(false)
    const [dataBack,setDataBack] = useState([])

//--------------------------------------------------------

    const itemSelect = (btn_shopName , btn_shop)=>{
        if (boxShowState === 'pm') {
            setBoxShowState('machin')
            // navigate('/task');
        } else {
            
            setShopName(btn_shopName);
            setShop(btn_shop);
            setBoxShowState('machin');
            if (categoryView === 'history') {
                setPageTitle(' سوابق فعالیتهای هفته '+historyWeek + ' سال ' + historyyear + ' سالن '+btn_shopName)
                
            } else {
                
                setPageTitle(section + ' / ' + btn_shopName)
            }
        }
    }

    const showList = (class1 , class2)=>{

        return(
            <div>
                <div className={class1}>
                    {uniqueShop.map((data , index)=> {
                        return(
                            <div key={index} className={class2} 
                                onClick={()=>itemSelect(data.Shop_Name , data.Shop)}>
                                {data.Shop_Name}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

  return (
    <div>
        <div>
            <h5>لیست سالن ها</h5>
        </div>
        {showList(props.clscon , props.clscat)}
    </div>
    )
}
