import { BrowserRouter as Router, Routes, NavLink, Route , Navigate , useNavigate } from 'react-router-dom'
import { useState, useEffect, createContext } from 'react';

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import TaskPage from './pages/TaskPage/TaskPage'
import Settings from './pages/SettingsPage/Settings';
// import PmList from './pages/PmList/PmList'
import Login from './pages/Login/Login'
import Main from './pages/Main/Main'
import Navbar from './components/Navbar';
import Users from './pages/UsersPage/Users';
import Summery from './pages/Summery/SummeryPage';
import Plan from './pages/Plan/PlanPage';
import History from './pages/HistoryPage/HistoryPage'


import './App.css';

import user_logo from './images/non-user-icon.svg' 
import user_logo1 from './images/user_1.jpg'
import user_logo2 from './images/user_2.jpg'

export const userdata = createContext();

function App() {

  const p2e = s => s.replace(/[۰-۹]/g , d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
  const pdate = new DateObject({ calendar: persian, locale: persian_fa});

  const [boxShowState, setBoxShowState] = useState('')
  const [allMachinList, setAllMachinList] = useState([])
  const [allPmList, setAllPmList] = useState([])
  const [uniquePlant, SetUniquePlant] = useState([])
  const [uniqueShop, SetUniqueShop] = useState([])
  const [uniquePeriod, SetUniquePeriod] = useState([])
  const [uniqueWorkCenter, SetUniqueWorkCenter] = useState([])
  const [uniqueOperatorList, SetUniqueOperatorList] = useState([])
  const [uniqueWeekOfYear, SetUniqueWeekOfYear] = useState([])
  const [uniqueYear, SetUniqueYear] = useState([])
  const [selectYear , setSelectYear] = useState(p2e(pdate.format('YYYY')))
  

  const [pmList, setPmlist] = useState([])
  const [finalPmList, setFinalPmlist] = useState([])
  const [result, setResult] = useState([])
  const [userList, setUserList] = useState([])
  const [shopOpin, setShopOpin] = useState([])
  const [machinOpin, setMachinOpin] = useState([])
 
  const [plant , setPlant] = useState('')
  const [plantName, setPlantName] = useState('')
  const [shop, setShop] = useState('')
  const [shopName, setShopName] = useState('')
  const [machin, setMachin] = useState('')
  const [machinName, setMachinName] = useState('')
  const [stationName, setStationName] = useState('')
  const [groupCode, setGroupCode] = useState('')
  const [workCenter, setWorkCenter] = useState('')
  const [userShopLevel, setUserShopLevel] = useState('');
  const [userWorkLevel, setUserWorkLevel] = useState('')
  const [userWorkLevel_text, setUserWorkLevel_text] = useState('')
  const [userPosition, setUserPosition] = useState('')

  const [pmPeriod, setPmPeriod] = useState('')
  const [stype, setStype] = useState('')
  const [sname, setSname] = useState('')
  const [pageTitle, setPageTitle] = useState('')
  const [toppageTitle, setToppageTitle] = useState(pageTitle)

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [fullName, setFullName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userImg, setUserImg] = useState(0);
  const [userData, setUserData] = useState();
  const [taskHint, setTaskHint] = useState();

  const [plantView, setPlantView] = useState(1);
  const [workView, setWorkView] = useState(1);
  const [taskView, setTaskView] = useState(false);
  const [settingsView, setSettingsView] = useState(false);
  const [summeryView, setSummeryView] = useState(false);
  const [categoryView, setCategoryView] = useState(false);

  const [pmCat, setPmCat] = useState(0);
  const [section, setSection] = useState('');
  const [isOperator, setIsOperator] = useState('');
  const [isOperatorName, setIsOperatorName] = useState('');
  const [isDisabled,setIsDisabled]=useState();
  const [weekOfCurrent,setWeekOfCurrent]=useState();
  const [weekFromStart,setWeekFromStart]=useState();
  const [startDate,setStartDate]=useState();
  const [machinCounter,setMachinCounter]=useState();
  const [response,setResponse]=useState([]);
  const [h_Response,setH_Response]=useState([]);
  const [executed,setExecuted]=useState([]);
  const [pmRender,setPmRender]=useState(false);
  const [pmShowWait,setPmShowWait]=useState(false);
  const [historyComment,setHistoryComment]=useState([])
  const [historyWeek, SetHistoryWeek] = useState()
  const [historyyear, SetHistoryyear] = useState()
  const [his_filterd_Btn,setHis_filterd_Btn] = useState('1W')
  const [res_filteres_by_machin,setRes_filteres_by_machin] = useState([])
  const [pm_filteres_by_machin,setPm_filteres_by_machin] = useState([])
  const [resultInfo,setResultInfo] = useState([])
  const [conditionData,setConditionData] = useState([])
  const [conditionBackData,setConditionBackData] = useState([])
  const [planBtn,setPlanBtn] = useState([])
  
  useEffect(()=>{
    const showState = localStorage.getItem('showState')
    const isLoggedin1 = localStorage.getItem('isLoggedin')
    const isuserdata= localStorage.getItem('userdata')
    const isusernumber = localStorage.getItem('usernumber')
    const isworkcenter= localStorage.getItem('workcenter')
    const isusershoplevel= localStorage.getItem('usershoplevel')
    const isuserworklevel= localStorage.getItem('userworklevel')

    // isuserdata && setUserData(Object.values(isuserdata))
    // setUserData(Object.values(isuserdata)[1])

    if (isLoggedin1 === '1') {
      // setBoxShowState(showState);
      // if (showState === 'pm') {
        // navigate('/pm')
      // }
      // setUserData(isuser);
      setIsLoggedin(true);
      setUserNumber(isusernumber);
      setWorkCenter(isworkcenter);
      setUserShopLevel(isusershoplevel);
      setUserWorkLevel(isuserworklevel);
      setUserWorkLevel_text(isuserworklevel);
      
    } else {
      setIsLoggedin(false)
    } 
    setBoxShowState((previous) => previous)
    
   },[]); 

  return (
    <div className="App"> 
      <Router>
        <userdata.Provider 
          value={{userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
            setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
            machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
            stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
            boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
            uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
            userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
            setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
            workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
            shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
            isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
            weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
            executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
            pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
            uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
            his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
            historyyear, SetHistoryyear, resultInfo,setResultInfo, uniqueYear, SetUniqueYear,conditionData,setConditionData,
            conditionBackData,setConditionBackData,selectYear , setSelectYear,userWorkLevel_text, setUserWorkLevel_text,planBtn,setPlanBtn
          }}>

          <Navbar />
          <Routes>
          
            {isLoggedin && <Route path='/login' element = {<Navigate to='/' />}/>}
            {!isLoggedin && <Route path='/login' element = {<Login />}/>}
            {isLoggedin && <Route path='/task' element = { <TaskPage />}/> }
            {isLoggedin && <Route path='/history' element = { <History />}/>}
            {isLoggedin && <Route path='/settings' element = { <Settings />}/>}
            {isLoggedin && <Route path='/users' element = { <Users />}/>}
            {isLoggedin && <Route path='/' element = { <Main />}/>}
            {isLoggedin && <Route path='/summery' element = { <Summery />}/>}
            {isLoggedin && <Route path='/plan' element = { <Plan />}/>}
            
            {!isLoggedin && <Route path='*' element = {<Navigate to='/login' />}/>}

          </Routes>
        </userdata.Provider>
      </Router>
    </div>
  );
}

export default App;
