 
import { useContext, useEffect, useState } from 'react';
import './listcat.css'
import { userdata } from '../App';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { useNavigate } from 'react-router-dom'
import { CleaningServices } from '@mui/icons-material';
import Chart from './Pie_Chart'
 
export default function ShopList(props) {

    const onBackButtonEvent = (e) => {
        e.preventDefault()
        console.log('first')
    
        if (categoryView === 'history') {
            setBoxShowState('history')
            navigate('/history')

        }else{
            if(workView==1){
                setBoxShowState('WorkCenterCategory') 
            }
            else if (plantView==1){
                setBoxShowState('plant') 
            }else{ 
                navigate('/'); 
            }
        }
    }

    const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
        setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
        machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
        stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
        boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
        uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
        userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
        setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
        workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
        shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
        isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
        weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
        executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
        pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition,
        uniqueWeekOfYear, SetUniqueWeekOfYear,historyComment,setHistoryComment,historyWeek, SetHistoryWeek,h_Response,setH_Response,
        his_filterd_Btn,setHis_filterd_Btn,res_filteres_by_machin,setRes_filteres_by_machin,pm_filteres_by_machin,setPm_filteres_by_machin,
        historyyear, SetHistoryyear
    } = useContext(userdata)

    
    const navigate = useNavigate()

    useEffect(()=>{

        if (categoryView === 'history') {
            setPageTitle( section +' / سوابق فعالیتهای هفته '+historyWeek + ' سال ' + historyyear)
        }else{
            setPageTitle( section )
        }

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
        }
    }, [])

    const [showWait,setShowWait] = useState(false)
    const [dataBack,setDataBack] = useState([])

//--------------------------------------------------------

    const itemSelect = (btn_shopName , btn_shop)=>{
        if (boxShowState === 'pm') {
            setBoxShowState('machin')
            // navigate('/task')
        } else {
            
            setShopName(btn_shopName);
            setShop(btn_shop);
            setBoxShowState('machin');
            if (categoryView === 'history') {
                setPageTitle(' سوابق فعالیتهای هفته '+historyWeek + ' سال ' + historyyear + ' سالن '+btn_shopName)
                
            } else {
                
                setPageTitle(section + ' / ' + btn_shopName)
            }
        }
    }

    const [ww, setWw] = useState(900)
    const [wh, setWh] = useState(300)
   
    const handleResize = () => {
    //   console.log('window.innerWidth = ',window.innerWidth)
      if (window.innerWidth < 468) {
        setWw(600)
        setWh(400)
      }
      else {
        setWw(900)
        setWh(300)
      }
    }
  
    useEffect(() => {
      handleResize()
      window.addEventListener("resize", handleResize)
    })

    const showList = (class1 , class2)=>{

        return(
            <div>
                <div className={class1}>
                    {uniqueShop.map((data , index)=> {
                        return(
                            <div key={index} className={class2} 
                                onClick={()=>itemSelect(data.Shop_Name , data.Shop)}>
                                {data.Shop_Name}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

  return (
    <div className='mainDiv1'>
        <div>
            <h4>لیست سالن ها</h4>
            <br/>
        </div>
        {categoryView === 'history' && 
        <div className='pieChart_con'>
            <Chart type = 'bar' ww = {ww} wh = {wh} />
        </div>}
        {showList(props.clscon , props.clscat)}
    </div>
    )
}