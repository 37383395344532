import React, { useContext, useEffect, useState } from 'react';

import { userdata } from '../App';
import { useFetch } from "../hooks/useFetch"

import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

import './listcat.css'
import Modal from "./Modal";
import non_user_logo from '../images/non-user-icon.svg'
import { useNavigate } from 'react-router-dom';
import { wait } from '@testing-library/user-event/dist/utils';

export default function UserList() { 

  const navigate = useNavigate()

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    setBoxShowState('WorkCenterCategory') 
  }

  const {userNumber , setUserNumber, isLoggedin, setIsLoggedin, fullName, setFullName,userImg,
    setUserImg, plant , setPlant, plantName, setPlantName, shop, setShop, shopName, setShopName,
    machin, setMachin, machinName, setMachinName,groupCode, setGroupCode,pmPeriod, setPmPeriod,
    stype, setStype, sname, setSname, pageTitle, setPageTitle,toppageTitle, setToppageTitle,
    boxShowState, setBoxShowState, allMachinList, setAllMachinList,allPmList, setAllPmList,section, setSection,
    uniquePlant, SetUniquePlant, uniqueShop, SetUniqueShop,workCenter, setWorkCenter,userShopLevel, setUserShopLevel,
    userWorkLevel, setUserWorkLevel, userData, setUserData,taskHint, setTaskHint,plantView, setPlantView,stationName,
    setStationName, uniquePeriod, SetUniquePeriod,pmCat, setPmCat, uniqueWorkCenter, SetUniqueWorkCenter,
    workView, setWorkView, pmList, setPmlist,settingsView, setSettingsView,userList, setUserList,
    shopOpin, setShopOpin,machinOpin, setMachinOpin,isOperator, setIsOperator,finalPmList, setFinalPmlist,
    isDisabled,setIsDisabled,result, setResult,isOperatorName, setIsOperatorName,weekOfCurrent,setWeekOfCurrent,
    weekFromStart,setWeekFromStart,startDate,setStartDate,machinCounter,setMachinCounter,response,setResponse,
    executed,setExecuted,summeryView, setSummeryView, taskView, setTaskView,uniqueOperatorList, SetUniqueOperatorList,
    pmShowWait,setPmShowWait,pmRender,setPmRender,categoryView, setCategoryView,userPosition, setUserPosition
  } = useContext(userdata)

  useEffect(()=>{
    // fetchData()
    refresh()
    // itemClick('همه')
    setPageTitle(section + ' / '+ 'لیست کاربران')
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [])

  var user_position = ''
  const [showWait,setShowWait] = useState(false)
  const [filteredUser,setFilteredUser] = useState([]) 
  const [selectedBtn,setSelectedBtn] = useState('all')

//--------------------------------------------------------
  const modalClosed=()=>{
  }

 //----------------------------------------------------------------------------------
 const fetchData = ()=>{
  const alluser = 'alluser'
  const pass = '0000'

  fetch('https://rezasayyahi.ir/dbtest/user_login.php',{

      method:'POST',
      headers:{
          'Accept' : 'applicaion/json',
          'Content-Type':'application/json',
      },
      body:JSON.stringify({
          usernumber: alluser,
          password: pass
      })
  }).then((response)=>response.json())
      .then((responseJson)=>{
          setUserList(responseJson)
          responseJson.map((user)=>{
            if (user.User_Number === userNumber ) {
              console.log('ok')
              setUserPosition(user.Position)
              itemClick('همه',user.Position)
              
              
            }
          })
          setShowWait(false)
          
          
  }).catch((error)=>{ 
      alert(error)
  })
}
//---------------------------------------------------------------------

const refresh=()=>{
  setShowWait(true)
  fetchData()
 }

//---------------------------------------------------------------------
 const clickHandeler = (user)=>{

 }

//---------------------------------------------------------------------
const itemClick = (shift,pos)=>{
    // console.log('ok2')
    // console.log('shift =' ,shift , ' user_position = ',pos )
    setShowWait(true)
    if (!(shift === 'همه')) {
      setSelectedBtn(shift)

      if (pos === 'man' || pos === 'planner' ) {
        setFilteredUser(userList.filter((item)=>{
          return(item.Work_Center === workCenter & item.Shift == (shift.toLowerCase()))
        }))
      } else if(pos === 'head') {
        setFilteredUser(userList.filter((item)=>{
          return(item.Work_Center === workCenter & item.Shift == (shift.toLowerCase()) & (item.Position === 'head'||item.Position === 'tech'))
        }))
      }

    } else {
      setSelectedBtn('همه')
      if (pos === 'man' || pos === 'planner' ) {
      setFilteredUser(userList.filter((item)=>{return(item.Work_Center === workCenter)}))
      }else if(pos === 'head'){
        setFilteredUser(userList.filter((item)=>{return(item.Work_Center === workCenter)&(item.Position === 'head'||item.Position === 'tech')}))
      }
    }
    
    setTimeout(()=>setShowWait(false),500)
  }
  
//--------------------------------------------------------------------
  const userListShow = (pos)=>{

    const shiftArr = ['همه']
    const uniqueShift = ([...new Map(userList.filter((item)=>{
      return(item.Work_Center === workCenter)
    }).map((user)=>[user.Shift, user])).values()])
    uniqueShift.map((shift)=>{
      shiftArr.push((shift.Shift).toUpperCase())
    })
        
    return(
      <>
        <div className='user_filter_con'>
          <div className='user_filter_cat'>
            <div className='user_filter_title' > <b>فیلتر بر اساس : </b> </div>
            {shiftArr.map((shift, index)=>{
              return(
                <div key={index} className={selectedBtn === shift ?'user_filter_item btn_active' :
                       'user_filter_item'} onClick={()=>itemClick(shift,pos)}>
                  {(shift === 'همه')? shift : 'شیفت ' + shift}
                </div>
              )
            })}
          </div>
        </div>
        <div className='user_btncon'>
            {filteredUser.map((data , index)=> {
              return(
                <div key={index} className='user_btncat'>
                    <div className='user_top'>
                      <img className='user_profile'
                          alt='person'
                          src = { non_user_logo}
                      />
                      <div className='user_fullName'>{data.Full_Name}</div>
                    </div>
                    <div>
                      <h4>شماره پرسنلی : {data.User_Number}</h4>
                      <br/>
                      <h5> بخش : {data.Section}</h5>
                    </div>
                    <br/>
                    <h4 style={{ borderTop:"1px solid var(--black)" , paddingTop:'1rem'}}>شیفت : {data.Shift}</h4>
                  </div>
                )
            })}
    </div>

      </>
    )
  }
//--------------------------------------------------------



  return (
    <>
      <div className='title_con'>
          <h4 className='title' >لیست کاربران</h4>
      </div>
      {(!(userPosition ==='')) && (userListShow(userPosition))}
      <div className="mod_con">
          <Modal show={showWait} modalClosed={modalClosed} className="Modal_Wait">
              <div className='modalText'>
                <div className="ring">
                    <span className='spann'></span>
                </div>
                <div className='modal_wait_Text'>
                    لطفا منتظر بمانید ...
                </div>
              </div>
          </Modal>
      </div>
      
      {/* <button onClick={()=>refresh()} className='btn_conf stikbottom'> بروزرسانی </button> */}
    </>
  )
}
