import React from 'react'
import '../pages/TaskPage/taskpage.css'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';



export default function footer() {
  return (
    <div className='footer'>
      <div className='footer_main'>
        <div className='footer_item'>

        </div>
        <div className='footer_item'>

        </div>
        <div className='footer_item'>

        </div>
        <div className='footer_item'>

        </div>
        <div className='footer_item'>

        </div>

      </div>
      <div className='footer_end'>
        <div className='footer_telegram'><SendOutlinedIcon/> <a href="https://t.me/Fire_Alarm_Net_bot" target="blank"> ورود به ربات تلگرام </a> </div>
        <div style={{display:'flex' , flexDirection:'row-reverse', justifyContent:'center', alignItems:'center'}}>
          Designed By Reza Sayyahi 
          <div> ver: 1.4 - </div> 
            <ArrowForwardOutlinedIcon/> 
            <a href="https://rezasayyahi.ir" target="_blank">Rezasayyahi.ir</a>
        </div>
      </div>
        
    </div>
  )
}
